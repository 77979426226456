<div class="container-fluid mx-2 my-3 bg-white" *ngIf="showFilter">
  <div class="row">
    <div class="col-1 solvenabold">FILTER</div>
    <div class="col-2"><select class="form-select form-select-sm " (change)="filterList()" [(ngModel)]="filterClass">
      <option *ngFor="let pc of filterProductClass" value='{{pc.val}}'>{{pc.name}}</option>
    </select></div>
    <div class="col-3"><select class="form-select form-select-sm " [(ngModel)]="filterCat" (change)="filterList()">
      <option *ngFor="let cat of filterCategory" value='{{cat}}'>{{cat}}</option>
    </select></div>
    <div class="col-3"><select class="form-select form-select-sm "  [(ngModel)]="filterProd" (change)="filterList()">
      <option *ngFor="let comp of filterProducer" value='{{comp.val}}'>{{comp.name}}</option>
    </select></div>
    <div class="col-3">Suche</div>
  </div>

</div>

<div class="mx-0">
  <table width="100%">
    <thead>
    <td>PZN</td>
    <td>Produkt</td>
    <td>Hersteller</td>
    <td align="right">Absatz</td>
    <td align="right" *ngIf="showTruePrice">Aktueller Preis</td>
    <td align="right" *ngIf="showFinalPrice">Finaler Preis</td>
    <td align="right" class="bg-alt">AVP</td>
    <td align="right" class="bg-alt">Differenz</td>
    <td align="right">Früherer Preis</td>
    <td align="right">Differenz</td>
    </thead>
    <tbody>
    <tr *ngFor="let product of productData">
      <td>{{product.PZN}}</td>
      <td>{{product.Produkt}}</td>
      <td>{{product.Hersteller}}</td>
      <td align="right">{{product.units}}</td>
      <td align="right" class="solvenabold" *ngIf="showTruePrice"> {{product.price | number: "0.2"}} EUR</td>
      <td align="right" class="solvenabold"  *ngIf="showFinalPrice">{{product.finalPrice | number: "0.2-2"}} EUR</td>
      <td align="right" class="bg-alt">{{product.AVP | number: "0.2-2"}} EUR</td>
      <td align="right" class="bg-alt">{{product.price - product.pricebefore | number: "0.2-2"}} EUR</td>
      <td align="right">{{product.pricebefore | number: "0.2-2"}} EUR</td>
      <td align="right">{{product.price - product.AVP | number: "0.2-2"}} EUR</td>
    </tr>
    </tbody>
  </table>
</div>
