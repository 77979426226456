<app-adminheader active="pharmacy"></app-adminheader>
<div class="container-fluid mx-2 my-3">
  <div class="row">
    <div><h3>Freigabe Preisliste - generiert am 22. Januar 2022</h3></div>
  </div>
  <div class="row kpi-header">
    <div class="col-3">{{pharmacy.name}}<br>{{pharmacy.street}}<br>{{pharmacy.zipcode}} {{pharmacy.city}}</div>
    <div class="col-1">

    </div>
    <div class="col-2"><span class="solvenabold">Anzahl PZN</span><br>
      <h3>{{pznCount}}</h3></div>
    <div class="col-2"><span class="solvenabold">Absatz</span><br>
      <h3>{{packages}}</h3></div>
    <div class="col-2"><span class="solvenabold">Umsatz Alt</span><br>
      <h3>{{turnoverOld | number: '0.2'}} &euro;</h3></div>
    <div class="col-2"><span class="solvenabold">Umsatz Neu</span><br>
      <h3>{{turnoverNew | number: '0.2'}} &euro;</h3></div>
  </div>

</div>
<div class="container-fluid mx-2 my-3 bg-white" *ngIf="showFilter">
  <div class="row">
    <div class="col-2">
      <div class="row">
        <div class="col-1"><i class="fa fa-filter"></i></div>
        <div class="col-10">
          <select class="form-select form-select-sm " (change)="filterList()" [(ngModel)]="filterSpecial">
            <option value="">Alle Preise</option>
            <option value="D">Dauerpreise</option>
            <option value="M">Manuell gesetzte Preise</option>
            <option value="N">Ausschluss</option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-2"><select class="form-select form-select-sm " (change)="filterList()" [(ngModel)]="filterClass">
      <option *ngFor="let pc of filterProductClass" value='{{pc.val}}'>{{pc.name}}</option>
    </select></div>
    <div class="col-3"><select class="form-select form-select-sm " [(ngModel)]="filterCat" (change)="filterList()">
      <option *ngFor="let cat of filterCategory" value='{{cat}}'>{{cat}}</option>
    </select></div>
    <div class="col-3"><select class="form-select form-select-sm " [(ngModel)]="filterProd" (change)="filterList()">
      <option *ngFor="let comp of filterProducer" value='{{comp.val}}'>{{comp.name}}</option>
    </select></div>
    <div class="col-2">Suche</div>
  </div>

</div>

<div class="container-fluid mx-2 my-3 bg-white">
  <div class="row">
    <div class="col-2" [ngClass]="selectedView == 1 ? 'solvenabold' : 'black-50'" (click)="selectList(1)">Auffällige Werte</div>
    <div class="col-2" [ngClass]="selectedView == 2 ? 'solvenabold' : 'black-50'" (click)="selectList(2)">Topseller</div>
    <div class="col-2" [ngClass]="selectedView == 3 ? 'solvenabold' : 'black-50'" (click)="selectList(3)">Gesamtliste</div>
  </div>
</div>
<div class="mx-4">
  <table width="100%">
    <thead>
    <td  (click)="setOrder('PZN')">PZN</td>
    <td ><span  (click)="setOrder('Produkt')">Produkt</span><br><span (click)="setOrder('Hersteller')" class="smalltext">Hersteller</span></td>
    <td  (click)="setOrder('units')" align="right">Absatz</td>
    <td align="right" class="bg-alt"  (click)="setOrder('AVP')">AVP<br><span class="smalltext">Diff True Price</span></td>
    <td align="right"  (click)="setOrder('rprice')">Umfeld Preis<br><span class="smalltext">Diff True Price</span></td>
    <td align="right"  (click)="setOrder('pricebefore')">Alter Preis<br><span class="smalltext">Diff True Price</span></td>
    <td align="right"  (click)="setOrder('price')">True Price</td>
    <td align="right">True Price <br>überschreiben</td>
    <td align="center" *ngIf="country=='DE'">Dauer-<br>preis</td>
    <td align="center">Aus-<br>schluss</td>
    </thead>
    <tbody>
    <tr *ngFor="let product of productData |  orderBy:order:reverse:true" >
      <td>{{product.PZN}}</td>
      <td><span class="solvenabold">{{product.Produkt}}</span><br><span class="smalltext">{{product.Hersteller}}</span></td>
      <td align="right">{{product.units}}</td>
      <td align="right" class="bg-alt">
        {{product.AVP | number: "0.2-2"}} &euro;<br>
        <span class="smalltext" *ngIf="product.price-product.AVP > 0 && !product.excludedprice" [ngClass]="getClassOf(product.price-product.AVP)">+</span>
        <span class="smalltext" *ngIf="!product.excludedprice" [ngClass]="getClassOf(product.price-product.AVP)">{{(product.price-product.AVP)/product.AVP*100 | number: "0.1-1"}}%</span>
      </td>

      <td align="right">{{  product.rprice | number: "0.2-2"}} &euro;<br>
        <span class="smalltext" *ngIf="product.price-product.rprice > 0 && !product.excludedprice" [ngClass]="getClassOf(product.price-product.rprice)">+</span>
        <span class="smalltext" *ngIf="!product.excludedprice" [ngClass]="getClassOf(product.price-product.rprice)">{{(product.price-product.rprice)/product.rprice*100 | number: "0.1-1"}}%</span>
      </td>
      <td align="right">{{product.pricebefore | number: "0.2-2"}} &euro;<br>
        <span class="smalltext" *ngIf="product.price-product.pricebefore > 0 && !product.excludedprice" [ngClass]="getClassOf(product.price-product.pricebefore)">+</span>
        <span class="smalltext" *ngIf="!product.excludedprice" [ngClass]="getClassOf(product.price-product.pricebefore)">{{(product.price-product.pricebefore)/product.pricebefore*100 | number: "0.1-1"}}%</span>
      </td>
      <td align="right" class="solvenabold">{{product.price | number: '0.2-2'}} &euro;</td>
      <td align="right" class="solvenabold"><span *ngIf="!product.excludedprice"><input  type="number" step="0.01"
                                                   style="text-align:right; width: 70px;" class="tableinput"
                                                                                         [(ngModel)]="product.finalPrice"
                                                   (change)="updateList()"> &euro;</span></td>
      <td align="center"><input type="checkbox" class="form-check-input" *ngIf="country=='DE'" [(ngModel)]="product.permanentprice"></td>
      <td align="center"><input type="checkbox" class="form-check-input" [(ngModel)]="product.excludedprice" (change)="updateList()"></td>
    </tr>
    </tbody>
  </table>
</div>
<footer class="fixed-bottom p-2 container-fluid solvena-bottom-toolbar" align="right ">
  <div class="row">
    <div class="col-6  bg-white" align="left">
      <button type="button" class="btn-secondary" (click)="backClick()">Zurück</button>
    </div>
    <div class="col-6  bg-white" align="right">
      <button class="btn-primary mx-4" type="button" (click)="saveClick()">Speichern und später freigeben</button>
      <button class="btn-primary" type="button" (click)="approveClick()">Preise freigeben</button>
    </div>
  </div>
</footer>
<div [ngStyle]="{'display':showModal}" class="modal" role="dialog" data-bs-backdrop="static">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">{{modalTitle}}</h3>
      </div>
      <div class="modal-body">
        <p>{{modalText}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="closeModal()">Schließen</button>
      </div>
    </div>
  </div>
</div>
