<nav id="mainnav" class="px-4 py-0 sticky-top mainnav navbar navbar-expand-lg container-fluid">
  <button class="navbar-toggler text-white"
          type="button"
          (click)="collapsed = !collapsed"
  >...</button>


  <div id="adminNav" class="mainnav collapse navbar-collapse " [class.collapse]="collapsed" (window:resize)="collapsed = true">
    <ul class="navbar-nav nav me-auto nav-solvena">
      <li [ngClass]="{'active': active===''}" class="nav-item justify-content-center"><a (click)="navigateTo('/admin/')">Home</a></li>
      <li [ngClass]="{'active': active==='pharmacies'}" class="nav-item"><a (click)="navigateTo('/admin/pharmacies')">Apotheken</a></li>
 <!--     <li [ngClass]="{'active': active==='settings'}" class="nav-item"><a (click)="navigateTo('/admin/settings')">Einstellungen</a></li>-->

    </ul>
    <ul class="nav navbar-nav navbar-right">
      <li [ngClass]="{'active': active==='profile'}" class="dropdown" appDropdown >{{username}}
        <a (click)="{}" class="dropdown-toggle" role="button"> </a>
        <ul class="dropdown-menu usermenu">
          <li class="mb-2"><a (click)="navigateTo('profile')">Mein Profil</a></li>
          <li><a (click)="gotoImprint()">Impressum</a></li>
          <li><a (click)="gotoDataProtection()">Datenschutz</a></li>
          <li class="mt-2"><a (click)="logout()">Logout</a></li>
        </ul>
      </li>
    </ul>
  </div>
</nav>
