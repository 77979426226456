import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminhomepageComponent } from './admin/adminhomepage/adminhomepage.component';
import { PharmacayhomepageComponent } from './pharmacy/pharmacayhomepage/pharmacayhomepage.component';
import { AdminheaderComponent } from './admin/adminheader/adminheader.component';
import { PharmacyheaderComponent } from './pharmacy/pharmacyheader/pharmacyheader.component';
import { PharmacylistComponent } from './sharedcontrols/pharmacylist/pharmacylist.component';
import { CaluclatetruepriceComponent } from './sharedcontrols/caluclatetrueprice/caluclatetrueprice.component';
import { PharmacydashboardComponent } from './pharmacy/pharmacydashboard/pharmacydashboard.component';
import { PricelistComponent } from './sharedcontrols/pricelist/pricelist.component';
import { TruepriceaprrovalComponent } from './pharmacy/truepriceaprroval/truepriceaprroval.component';
import { PricelistimportComponent } from './pharmacy/pricelistimport/pricelistimport.component';
import {DropdownDirective} from '../shared/dropdown.directive';
import { LoginComponent } from './login/login.component';
import { FormsModule} from '@angular/forms';
import { PharmacypricelistComponent } from './pharmacy/pharmacypricelist/pharmacypricelist.component';
import { PharmacycalctruepriceComponent } from './pharmacy/pharmacycalctrueprice/pharmacycalctrueprice.component';
import { PricetableComponent } from './sharedcontrols/tables/pricetable/pricetable.component';
import { AdminsettingsComponent } from './admin/adminsettings/adminsettings.component';
import { AdminpharmacyComponent } from './admin/adminpharmacy/adminpharmacy.component';
import { AdminpricelistComponent } from './admin/adminpricelist/adminpricelist.component';
import { AdminsimulationComponent } from './admin/adminsimulation/adminsimulation.component';
import { AdminhotfixComponent } from './admin/adminhotfix/adminhotfix.component';
import { TruepricetableComponent } from './sharedcontrols/tables/truepricetable/truepricetable.component';
import { ApprovetableComponent } from './sharedcontrols/tables/approvetable/approvetable.component';
import { HotfixtableComponent } from './sharedcontrols/tables/hotfixtable/hotfixtable.component';
import { AdminapproveComponent } from './admin/adminapprove/adminapprove.component';
import { AdminuploadComponent } from './admin/adminupload/adminupload.component';
import {NgxUploaderModule} from 'ngx-uploader';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { de_DE } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import de from '@angular/common/locales/de';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NzSliderModule} from 'ng-zorro-antd/slider';
import {SelectContextComponent} from './sharedcontrols/selectcontext/selectcontext.component';
import {OrderModule} from 'ngx-order-pipe';
import {NgxSliderModule} from '@angular-slider/ngx-slider';
import {GlobalContextService} from './globalContext.service';


registerLocaleData(de);

@NgModule({
  declarations: [
    DropdownDirective,
    AppComponent,
    AdminhomepageComponent,
    PharmacayhomepageComponent,
    AdminheaderComponent,
    PharmacyheaderComponent,
    PharmacylistComponent,
    CaluclatetruepriceComponent,
    PharmacydashboardComponent,
    PricelistComponent,
    TruepriceaprrovalComponent,
    PricelistimportComponent,
    LoginComponent,
    PharmacypricelistComponent,
    PharmacycalctruepriceComponent,
    PricetableComponent,
    AdminsettingsComponent,
    AdminpharmacyComponent,
    AdminpricelistComponent,
    AdminsimulationComponent,
    AdminhotfixComponent,
    TruepricetableComponent,
    ApprovetableComponent,
    HotfixtableComponent,
    AdminapproveComponent,
    AdminuploadComponent,
    SelectContextComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    NgxUploaderModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NzSliderModule,
    OrderModule,
    NgxSliderModule
  ],
  providers: [{ provide: NZ_I18N, useValue: de_DE }, {provide: LOCALE_ID, useValue: 'de'}, GlobalContextService],
  bootstrap: [AppComponent]
})
export class AppModule { }
