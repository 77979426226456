import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AdminhomepageComponent} from './admin/adminhomepage/adminhomepage.component';
import {PharmacayhomepageComponent} from './pharmacy/pharmacayhomepage/pharmacayhomepage.component';
import {LoginComponent} from './login/login.component';
import {PricelistComponent} from './sharedcontrols/pricelist/pricelist.component';
import {PharmacypricelistComponent} from './pharmacy/pharmacypricelist/pharmacypricelist.component';
import {TruepriceaprrovalComponent} from './pharmacy/truepriceaprroval/truepriceaprroval.component';
import {PharmacycalctruepriceComponent} from './pharmacy/pharmacycalctrueprice/pharmacycalctrueprice.component';
import {AdminsettingsComponent} from './admin/adminsettings/adminsettings.component';
import {AdminpharmacyComponent} from './admin/adminpharmacy/adminpharmacy.component';
import {AdminsimulationComponent} from './admin/adminsimulation/adminsimulation.component';
import {AdminpricelistComponent} from './admin/adminpricelist/adminpricelist.component';
import {AdminhotfixComponent} from './admin/adminhotfix/adminhotfix.component';
import {AdminapproveComponent} from './admin/adminapprove/adminapprove.component';
import {AdminuploadComponent} from './admin/adminupload/adminupload.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'admin',
    component: AdminhomepageComponent
  },
  {
    path: 'admin/settings',
    component: AdminsettingsComponent
  },
  {
    path: 'admin/pharmacies',
    component: AdminhomepageComponent
  },  {
    path: 'admin/pharmacies/:id/upload',
    component: AdminuploadComponent
  },  {
    path: 'admin/pharmacies/:id/simulation/:country/:category',
    component: AdminsimulationComponent
  },
  {
    path: 'admin/pharmacies/:id/simulation',
    component: AdminsimulationComponent
  },  {
    path: 'admin/pharmacies/:id/approve',
    component: AdminapproveComponent
  },
  {
    path: 'admin/pharmacies/:id/pricelist',
    component: AdminpricelistComponent
  },
  {
    path: 'admin/pharmacies/:id/pricelist/:category',
    component: AdminpricelistComponent
  },
  {
    path: 'admin/pharmacies/:id/hotfix',
    component: AdminhotfixComponent
  },
  {
    path: 'admin/pharmacies/:id',
    component: AdminpharmacyComponent
  },
  {
    path: 'pharmacy',
    component: PharmacayhomepageComponent
  },
  {
    path: 'pharmacy/pricelist',
    component: PharmacypricelistComponent
  },  {
    path: 'pharmacy/pricelist/:category',
    component: PharmacypricelistComponent
  },
  {
    path: 'pharmacy/approvepricelist',
    component: TruepriceaprrovalComponent
  },
  {
    path: 'pharmacy/recalculatepricelist',
    component: PharmacycalctruepriceComponent
  },
  {
    path: '**',
    redirectTo: '404',
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
