<div class="container-fluid mx-2 my-3">
  <div class="row">
    <div class="col-8"><h3>Aktuelle Preisliste {{filterCategory}} - freigegeben am 22. Januar 2022</h3></div>

    <div class="col-4 d-flex justify-content-end">
      <div>
        <button class="btn-primary mx-1 p-2" type="button" *ngIf="!editMode" (click)="edit()"><i style="font-size:2em" class="fa fa-edit"></i><br>Bearbeiten
        </button>
      </div>
      <div *ngIf="!editMode">
        <button disabled class="btn-primary mx-4" type="submit"><i class="fa fa-file-o"></i> CSV Download</button>
        <br>
        <button disabled class="btn-primary mx-4 my-2" type="submit"><i class="fa fa-file-pdf-o"></i> PDF Download</button>
      </div>

    </div>
  </div>
  <div class="row kpi-header">
    <div class="col-6">{{pharmacy.name}}<br>{{pharmacy.street}}<br>{{pharmacy.zipcode}} {{pharmacy.city}}</div>
    <div class="col-6">
      <div class="row mt-4"><div class="col-8" *ngIf="country=='DE'"></div>
      <div class="col-4 " *ngIf="country=='DE'"><span class="tag">D</span> Dauerpreis</div>
        <div class="col-8"></div>
      <div class="col-4 "><span class="tag">M</span> Manuell gesetzter Preis<br></div>
        <div class="col-8"></div>
        <div class="col-4 "><span class="tag">N</span> Nicht berücksichtigt<br></div>
      </div>
    </div>
  </div>
  <app-pricetable [country]="country" [productData]="productData" [presetFilterClass]="filterCategory" [showFilter]="true" [showTruePrice]="true" [editMode]="editMode"></app-pricetable>
</div>
<footer class="fixed-bottom p-2 container-fluid solvena-bottom-toolbar" align="right ">
  <div class="row">
    <div class="col-6  bg-white" align="left">
      <button type="button" class="btn-secondary" (click)="backClick()">Zurück</button>
    </div>
    <div class="col-6  bg-white" align="right">
      <button *ngIf="editMode" class="btn-secondary mx-4" type="submit" (click)="cancel()">Abbrechen</button>
      <button *ngIf="editMode" class="btn-primary" type="submit" (click)="save()">Speichern</button>
    </div>
  </div>
</footer>
<div [ngStyle]="{'display':showModal}" class="modal" role="dialog" data-bs-backdrop="static">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">{{modalTitle}}</h3>
      </div>
      <div class="modal-body">
        <p>{{modalText}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="closeModal()">Schließen</button>
      </div>
    </div>
  </div>
</div>
