import {Component, ElementRef, EventEmitter, Input, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ChangeContext} from '@angular-slider/ngx-slider';
import pharmacydata from '../../../assets/dummydata/pharmacies.json';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-caluclatetrueprice',
  templateUrl: './caluclatetrueprice.component.html',
  styleUrls: ['./caluclatetrueprice.component.css']
})
export class CaluclatetruepriceComponent implements OnInit {
  @ViewChild('progressElement')  progress!: ElementRef<HTMLProgressElement>;
  @Input() headline = '';
  step = 1;
  progressNavigationData = [
    {label: 'Produktgruppen', step: 1},
    {label: 'Produktliste', step: 2},
    {label: 'Rundung, Grenzwerte', step: 3},
    {label: 'Bestätigung', step: 4},
  ];
  country = 'DE';
  pznCount = 0;
  packages = 0;
  turnoverOld = 0;
  turnoverNew = 0;
  umfeld = 'ravp';
  warengruppe = 'freiwahl';
  productData = [] as any;
  clusterProductData = [] as any;
  showFilter = true;
  showModal = 'none';
  selectedCluster = 0;
  rundung = 'keine';
  aclusterchange = 0;
  lastDigit = '0';
  grenzwert = 10;
  grenzwertplus = 0;
  options = {};

  productStatistics = {
    avpCurrent: 0,
    ravpCurrent: 0,
    ravpOld:0,
    avpOld: 0,
    lowestRavp:0,
    highestRavp:0,
    trueprice:0
  };
  truepriceStat = 0;

  showClusterData = true;
  pharmacyId = 0;
  pharmacy = <any> {};
  filterCluster = '';


  pecluster = [] as any;

  peclusterde = [{
    id: 0,
    name: 'Cluster Hohe Preiselastizität',
    pzn: 0,
    percentage: 0,
    change: 0,
    averageavp: 0,
    averageravp: 0,
    averagenew: 0,
    oldprice: 0,
    lowestravp: 0,
    highestravp: 0,
    turnoverold: 0,
    turnovernew: 0,
    ticksArray: [],
    display: false,
    slideroptions: {
      floor: -20,
      ceil: 20,
      step: 0.1,
      showTickValues: true,
      hideLimitLabels: true,
      hidePointerLabels: true,
      /*    showSelectionBar: true,
          showSelectionBarFromValue: 0, */
      ticksArray: [0, 1, 2, 3],
      getPointerColor: (value: number): string => {
        return '#4C8D2B';
      },
      getSelectionBarColor: (minValue: number, maxValue?: number): string => {
        return '#4C8D2B';
      },
      getTickColor: (value: number): string => {
        return this.sliderTickColor(value, 0, '');
      },
      getLegend: (value: number): string => {
        return this.sliderLegend(value, 0, '');
      }
    },
    sliderAvpValue: 0,
    sliderRAvpValue: 0,
    sliderCurPriceValue: 0,
    manualRefresh: new EventEmitter<void>(),
  },
    {
      id: 1,
      label: '1',
      name: 'Cluster Mittlere Preiselastizität',
      pzn: 0,
      percentage: 0,
      change: 0,
      averageavp: 0,
      averageravp: 0,
      averagenew: 0,
      oldprice: 0,
      lowestravp: 0,
      highestravp: 0,
      turnovernew: 0,
      turnoverold: 0,
      display: false,
      slideroptions: {
        floor: -20,
        ceil: 20,
        step: 0.1,
        showTickValues: true,
        hideLimitLabels: true,
        hidePointerLabels: true,
        /*    showSelectionBar: true,
            showSelectionBarFromValue: 0, */
        ticksArray: [],
        getPointerColor: (value: number): string => {
          return '#4C8D2B';
        },
        getSelectionBarColor: (minValue: number, maxValue?: number): string => {
          return '#4C8D2B';
        },
        getTickColor: (value: number): string => {
          return this.sliderTickColor(value, 1, '');
        },
        getLegend: (value: number): string => {
          return this.sliderLegend(value, 1, '');
        }
      },
      sliderAvpValue: 0,
      sliderRAvpValue: 0,
      sliderCurPriceValue: 0,
      manualRefresh: new EventEmitter<void>(),
    },
    {
      id: 2,
      label: '2',
      name: 'Cluster Niedrige Preiselastizität',
      pzn: 0,
      percentage: 0,
      change: 0,
      averageavp: 0,
      averageravp: 0,
      averagenew: 0,
      oldprice: 0,
      lowestravp: 0,
      highestravp: 0,
      turnovernew: 0,
      turnoverold: 0,
      display: false,
      slideroptions: {
        floor: -20,
        ceil: 20,
        step: 0.1,
        showTickValues: true,
        hideLimitLabels: true,
        hidePointerLabels: true,
        /*    showSelectionBar: true,
            showSelectionBarFromValue: 0, */
        ticksArray: [],
        getPointerColor: (value: number): string => {
          return '#4C8D2B';
        },
        getSelectionBarColor: (minValue: number, maxValue?: number): string => {
          return '#4C8D2B';
        },
        getTickColor: (value: number): string => {
          return this.sliderTickColor(value, 2, '');
        },
        getLegend: (value: number): string => {
          return this.sliderLegend(value, 2, '');
        }
      },
      sliderAvpValue: 0,
      sliderRAvpValue: 0,
      sliderCurPriceValue: 0,
      sliderLabels: <any> [],
      manualRefresh: new EventEmitter<void>(),
    },
    {
      id: 3,
      label: '3',
      name: 'Cluster Keine Preiselastizität',
      pzn: 0,
      percentage: 0,
      change: 0,
      averageavp: 0,
      averageravp: 0,
      averagenew: 0,
      oldprice: 0,
      lowestravp: 0,
      highestravp: 0,
      turnovernew: 0,
      turnoverold: 0,
      display: false,
      slideroptions: {
        floor: -20,
        ceil: 20,
        step: 0.1,
        showTickValues: true,
        hideLimitLabels: true,
        hidePointerLabels: true,
        /*    showSelectionBar: true,
            showSelectionBarFromValue: 0, */
        ticksArray: [],
        getPointerColor: (value: number): string => {
          return '#4C8D2B';
        },
        getSelectionBarColor: (minValue: number, maxValue?: number): string => {
          return '#4C8D2B';
        },
        getTickColor: (value: number): string => {
          return this.sliderTickColor(value, 3, '');
        },
        getLegend: (value: number): string => {
          return this.sliderLegend(value, 3, '');
        }
      },
      sliderValue: 0,
      sliderCurPriceValue: 0,
      sliderLabels: <any> [],
      manualRefresh: new EventEmitter<void>(),
    }];

  peclusterat = [{
    id: 0,
    name: 'Cluster Hohe Preiselastizität',
    showSubcluster: false,
    pzn: 0,
    percentage: 0,
    change: 0,
    averageavp: 0,
    averageravp: 0,
    averagenew: 0,
    oldprice: 0,
    lowestravp: 0,
    highestravp: 0,
    turnoverold: 0,
    turnovernew: 0,
    ticksArray: [],
    display: false,
    slideroptions: {
      floor: -20,
      ceil: 20,
      step: 0.1,
      showTickValues: true,
      hideLimitLabels: true,
      hidePointerLabels: true,
      /*    showSelectionBar: true,
          showSelectionBarFromValue: 0, */
      ticksArray: [0, 1, 2, 3],
      getPointerColor: (value: number): string => {
        return '#4C8D2B';
      },
      getSelectionBarColor: (minValue: number, maxValue?: number): string => {
        return '#4C8D2B';
      },
      getTickColor: (value: number): string => {
        return this.sliderTickColor(value, 0, '');
      },
      getLegend: (value: number): string => {
        return this.sliderLegend(value, 0, '');
      }
    },
    sliderAvpValue: 0,
    sliderRAvpValue: 0,
    sliderCurPriceValue: 0,
    manualRefresh: new EventEmitter<void>(),
    subclusters: [{
      id: 0,
      label: 'A',
      name: 'Schnelldreher',
      pzn: 0,
      percentage: 0,
      change: 0,
      averageavp: 0,
      averageravp: 0,
      averagenew: 0,
      oldprice: 0,
      lowestravp: 0,
      highestravp: 0,
      turnoverold: 0,
      turnovernew: 0,
      ticksArray: [],
      display: false,
      slideroptions: {
        floor: -20,
        ceil: 20,
        step: 0.1,
        showTickValues: true,
        hideLimitLabels: true,
        hidePointerLabels: true,
        /*    showSelectionBar: true,
            showSelectionBarFromValue: 0, */
        ticksArray: [0, 1, 2, 3],
        getPointerColor: (value: number): string => {
          return '#4C8D2B';
        },
        getSelectionBarColor: (minValue: number, maxValue?: number): string => {
          return '#4C8D2B';
        },
        getTickColor: (value: number): string => {
          return this.sliderTickColor(value, 0, 'A');
        },
        getLegend: (value: number): string => {
          return this.sliderLegend(value, 0, 'A');
        }
      },
      sliderAvpValue: 0,
      sliderRAvpValue: 0,
      sliderCurPriceValue: 0,
      manualRefresh: new EventEmitter<void>(),
    }, {
      id: 0,
      label: 'B',
      name: 'Mitteldreher',
      pzn: 0,
      percentage: 0,
      change: 0,
      averageavp: 0,
      averageravp: 0,
      averagenew: 0,
      oldprice: 0,
      lowestravp: 0,
      highestravp: 0,
      turnoverold: 0,
      turnovernew: 0,
      ticksArray: [],
      display: false,
      slideroptions: {
        floor: -20,
        ceil: 20,
        step: 0.1,
        showTickValues: true,
        hideLimitLabels: true,
        hidePointerLabels: true,
        /*    showSelectionBar: true,
            showSelectionBarFromValue: 0, */
        ticksArray: [0, 1, 2, 3],
        getPointerColor: (value: number): string => {
          return '#4C8D2B';
        },
        getSelectionBarColor: (minValue: number, maxValue?: number): string => {
          return '#4C8D2B';
        },
        getTickColor: (value: number): string => {
          return this.sliderTickColor(value, 0, 'B');
        },
        getLegend: (value: number): string => {
          return this.sliderLegend(value, 0, 'B');
        }
      },
      sliderAvpValue: 0,
      sliderRAvpValue: 0,
      sliderCurPriceValue: 0,
      manualRefresh: new EventEmitter<void>(),
    }, {
      id: 0,
      label: 'C',
      name: 'Langsamdreher',
      pzn: 0,
      percentage: 0,
      change: 0,
      averageavp: 0,
      averageravp: 0,
      averagenew: 0,
      oldprice: 0,
      lowestravp: 0,
      highestravp: 0,
      turnoverold: 0,
      turnovernew: 0,
      ticksArray: [],
      display: false,
      slideroptions: {
        floor: -20,
        ceil: 20,
        step: 0.1,
        showTickValues: true,
        hideLimitLabels: true,
        hidePointerLabels: true,
        /*    showSelectionBar: true,
            showSelectionBarFromValue: 0, */
        ticksArray: [0, 1, 2, 3],
        getPointerColor: (value: number): string => {
          return '#4C8D2B';
        },
        getSelectionBarColor: (minValue: number, maxValue?: number): string => {
          return '#4C8D2B';
        },
        getTickColor: (value: number): string => {
          return this.sliderTickColor(value, 0, 'C');
        },
        getLegend: (value: number): string => {
          return this.sliderLegend(value, 0, 'C');
        }
      },
      sliderAvpValue: 0,
      sliderRAvpValue: 0,
      sliderCurPriceValue: 0,
      manualRefresh: new EventEmitter<void>(),
    }]
  },
    {
      id: 1,
      name: 'Cluster Mittlere Preiselastizität',
      showSubcluster: false,
      pzn: 0,
      percentage: 0,
      change: 0,
      averageavp: 0,
      averageravp: 0,
      averagenew: 0,
      oldprice: 0,
      lowestravp: 0,
      highestravp: 0,
      turnovernew: 0,
      turnoverold: 0,
      display: false,
      slideroptions: {
        floor: -20,
        ceil: 20,
        step: 0.1,
        showTickValues: true,
        hideLimitLabels: true,
        hidePointerLabels: true,
        /*    showSelectionBar: true,
            showSelectionBarFromValue: 0, */
        ticksArray: [],
        getPointerColor: (value: number): string => {
          return '#4C8D2B';
        },
        getSelectionBarColor: (minValue: number, maxValue?: number): string => {
          return '#4C8D2B';
        },
        getTickColor: (value: number): string => {
          return this.sliderTickColor(value, 1, '');
        },
        getLegend: (value: number): string => {
          return this.sliderLegend(value, 1, '');
        }
      },
      sliderAvpValue: 0,
      sliderRAvpValue: 0,
      sliderCurPriceValue: 0,
      manualRefresh: new EventEmitter<void>(),
      subclusters: [{
        id: 1,
        label: 'A',
        name: 'Schnelldreher',
        pzn: 0,
        percentage: 0,
        change: 0,
        averageavp: 0,
        averageravp: 0,
        averagenew: 0,
        oldprice: 0,
        lowestravp: 0,
        highestravp: 0,
        turnoverold: 0,
        turnovernew: 0,
        ticksArray: [],
        display: false,
        slideroptions: {
          floor: -20,
          ceil: 20,
          step: 0.1,
          showTickValues: true,
          hideLimitLabels: true,
          hidePointerLabels: true,
          /*    showSelectionBar: true,
              showSelectionBarFromValue: 0, */
          ticksArray: [0, 1, 2, 3],
          getPointerColor: (value: number): string => {
            return '#4C8D2B';
          },
          getSelectionBarColor: (minValue: number, maxValue?: number): string => {
            return '#4C8D2B';
          },
          getTickColor: (value: number): string => {
            return this.sliderTickColor(value, 1, 'A');
          },
          getLegend: (value: number): string => {
            return this.sliderLegend(value, 1, 'A');
          }
        },
        sliderAvpValue: 0,
        sliderRAvpValue: 0,
        sliderCurPriceValue: 0,
        manualRefresh: new EventEmitter<void>(),
      }, {
        id: 1,
        label: 'B',
        name: 'Mitteldreher',
        pzn: 0,
        percentage: 0,
        change: 0,
        averageavp: 0,
        averageravp: 0,
        averagenew: 0,
        oldprice: 0,
        lowestravp: 0,
        highestravp: 0,
        turnoverold: 0,
        turnovernew: 0,
        ticksArray: [],
        display: false,
        slideroptions: {
          floor: -20,
          ceil: 20,
          step: 0.1,
          showTickValues: true,
          hideLimitLabels: true,
          hidePointerLabels: true,
          /*    showSelectionBar: true,
              showSelectionBarFromValue: 0, */
          ticksArray: [0, 1, 2, 3],
          getPointerColor: (value: number): string => {
            return '#4C8D2B';
          },
          getSelectionBarColor: (minValue: number, maxValue?: number): string => {
            return '#4C8D2B';
          },
          getTickColor: (value: number): string => {
            return this.sliderTickColor(value, 1, 'B');
          },
          getLegend: (value: number): string => {
            return this.sliderLegend(value, 1, 'B');
          }
        },
        sliderAvpValue: 0,
        sliderRAvpValue: 0,
        sliderCurPriceValue: 0,
        manualRefresh: new EventEmitter<void>(),
      }, {
        id: 1,
        label: 'C',
        name: 'Langsamdreher',
        pzn: 0,
        percentage: 0,
        change: 0,
        averageavp: 0,
        averageravp: 0,
        averagenew: 0,
        oldprice: 0,
        lowestravp: 0,
        highestravp: 0,
        turnoverold: 0,
        turnovernew: 0,
        ticksArray: [],
        display: false,
        slideroptions: {
          floor: -20,
          ceil: 20,
          step: 0.1,
          showTickValues: true,
          hideLimitLabels: true,
          hidePointerLabels: true,
          /*    showSelectionBar: true,
              showSelectionBarFromValue: 0, */
          ticksArray: [0, 1, 2, 3],
          getPointerColor: (value: number): string => {
            return '#4C8D2B';
          },
          getSelectionBarColor: (minValue: number, maxValue?: number): string => {
            return '#4C8D2B';
          },
          getTickColor: (value: number): string => {
            return this.sliderTickColor(value, 1, 'C');
          },
          getLegend: (value: number): string => {
            return this.sliderLegend(value, 1, 'C');
          }
        },
        sliderAvpValue: 0,
        sliderRAvpValue: 0,
        sliderCurPriceValue: 0,
        manualRefresh: new EventEmitter<void>(),
      }]

    },
    {
      id: 2,
      name: 'Cluster Niedrige Preiselastizität',
      showSubcluster: false,
      pzn: 0,
      percentage: 0,
      change: 0,
      averageavp: 0,
      averageravp: 0,
      averagenew: 0,
      oldprice: 0,
      lowestravp: 0,
      highestravp: 0,
      turnovernew: 0,
      turnoverold: 0,
      display: false,
      slideroptions: {
        floor: -20,
        ceil: 20,
        step: 0.1,
        showTickValues: true,
        hideLimitLabels: true,
        hidePointerLabels: true,
        /*    showSelectionBar: true,
            showSelectionBarFromValue: 0, */
        ticksArray: [],
        getPointerColor: (value: number): string => {
          return '#4C8D2B';
        },
        getSelectionBarColor: (minValue: number, maxValue?: number): string => {
          return '#4C8D2B';
        },
        getTickColor: (value: number): string => {
          return this.sliderTickColor(value, 2, '');
        },
        getLegend: (value: number): string => {
          return this.sliderLegend(value, 2, '');
        }
      },
      sliderAvpValue: 0,
      sliderRAvpValue: 0,
      sliderCurPriceValue: 0,
      sliderLabels: <any> [],
      manualRefresh: new EventEmitter<void>(),
      subclusters: [{
        id: 2,
        label: 'A',
        name: 'Schnelldreher',
        pzn: 0,
        percentage: 0,
        change: 0,
        averageavp: 0,
        averageravp: 0,
        averagenew: 0,
        oldprice: 0,
        lowestravp: 0,
        highestravp: 0,
        turnoverold: 0,
        turnovernew: 0,
        ticksArray: [],
        display: false,
        slideroptions: {
          floor: -20,
          ceil: 20,
          step: 0.1,
          showTickValues: true,
          hideLimitLabels: true,
          hidePointerLabels: true,
          /*    showSelectionBar: true,
              showSelectionBarFromValue: 0, */
          ticksArray: [0, 1, 2, 3],
          getPointerColor: (value: number): string => {
            return '#4C8D2B';
          },
          getSelectionBarColor: (minValue: number, maxValue?: number): string => {
            return '#4C8D2B';
          },
          getTickColor: (value: number): string => {
            return this.sliderTickColor(value, 2, 'A');
          },
          getLegend: (value: number): string => {
            return this.sliderLegend(value, 2, 'A');
          }
        },
        sliderAvpValue: 0,
        sliderRAvpValue: 0,
        sliderCurPriceValue: 0,
        manualRefresh: new EventEmitter<void>(),
      }, {
        id: 2,
        label: 'B',
        name: 'Mitteldreher',
        pzn: 0,
        percentage: 0,
        change: 0,
        averageavp: 0,
        averageravp: 0,
        averagenew: 0,
        oldprice: 0,
        lowestravp: 0,
        highestravp: 0,
        turnoverold: 0,
        turnovernew: 0,
        ticksArray: [],
        display: false,
        slideroptions: {
          floor: -20,
          ceil: 20,
          step: 0.1,
          showTickValues: true,
          hideLimitLabels: true,
          hidePointerLabels: true,
          /*    showSelectionBar: true,
              showSelectionBarFromValue: 0, */
          ticksArray: [0, 1, 2, 3],
          getPointerColor: (value: number): string => {
            return '#4C8D2B';
          },
          getSelectionBarColor: (minValue: number, maxValue?: number): string => {
            return '#4C8D2B';
          },
          getTickColor: (value: number): string => {
            return this.sliderTickColor(value, 2, 'B');
          },
          getLegend: (value: number): string => {
            return this.sliderLegend(value, 2, 'B');
          }
        },
        sliderAvpValue: 0,
        sliderRAvpValue: 0,
        sliderCurPriceValue: 0,
        manualRefresh: new EventEmitter<void>(),
      }, {
        id: 2,
        label: 'C',
        name: 'Langsamdreher',
        pzn: 0,
        percentage: 0,
        change: 0,
        averageavp: 0,
        averageravp: 0,
        averagenew: 0,
        oldprice: 0,
        lowestravp: 0,
        highestravp: 0,
        turnoverold: 0,
        turnovernew: 0,
        ticksArray: [],
        display: false,
        slideroptions: {
          floor: -20,
          ceil: 20,
          step: 0.1,
          showTickValues: true,
          hideLimitLabels: true,
          hidePointerLabels: true,
          /*    showSelectionBar: true,
              showSelectionBarFromValue: 0, */
          ticksArray: [0, 1, 2, 3],
          getPointerColor: (value: number): string => {
            return '#4C8D2B';
          },
          getSelectionBarColor: (minValue: number, maxValue?: number): string => {
            return '#4C8D2B';
          },
          getTickColor: (value: number): string => {
            return this.sliderTickColor(value, 2, 'C');
          },
          getLegend: (value: number): string => {
            return this.sliderLegend(value, 2, 'C');
          }
        },
        sliderAvpValue: 0,
        sliderRAvpValue: 0,
        sliderCurPriceValue: 0,
        manualRefresh: new EventEmitter<void>(),
      }]

    },
    {
      id: 4,
      name: 'Cluster Keine Preiselastizität',
      showSubcluster: false,
      pzn: 0,
      percentage: 0,
      change: 0,
      averageavp: 0,
      averageravp: 0,
      averagenew: 0,
      oldprice: 0,
      lowestravp: 0,
      highestravp: 0,
      turnovernew: 0,
      turnoverold: 0,
      display: false,
      slideroptions: {
        floor: -20,
        ceil: 20,
        step: 0.1,
        showTickValues: true,
        hideLimitLabels: true,
        hidePointerLabels: true,
        /*    showSelectionBar: true,
            showSelectionBarFromValue: 0, */
        ticksArray: [],
        getPointerColor: (value: number): string => {
          return '#4C8D2B';
        },
        getSelectionBarColor: (minValue: number, maxValue?: number): string => {
          return '#4C8D2B';
        },
        getTickColor: (value: number): string => {
          return this.sliderTickColor(value, 4, '');
        },
        getLegend: (value: number): string => {
          return this.sliderLegend(value, 4, '');
        }
      },
      sliderValue: 0,
      sliderCurPriceValue: 0,
      sliderLabels: <any> [],
      manualRefresh: new EventEmitter<void>(),
      subclusters: [{
        id: 4,
        label: 'A',
        name: 'Schnelldreher',
        pzn: 0,
        percentage: 0,
        change: 0,
        averageavp: 0,
        averageravp: 0,
        averagenew: 0,
        oldprice: 0,
        lowestravp: 0,
        highestravp: 0,
        turnoverold: 0,
        turnovernew: 0,
        ticksArray: [],
        display: false,
        slideroptions: {
          floor: -20,
          ceil: 20,
          step: 0.1,
          showTickValues: true,
          hideLimitLabels: true,
          hidePointerLabels: true,
          /*    showSelectionBar: true,
              showSelectionBarFromValue: 0, */
          ticksArray: [0, 1, 2, 3],
          getPointerColor: (value: number): string => {
            return '#4C8D2B';
          },
          getSelectionBarColor: (minValue: number, maxValue?: number): string => {
            return '#4C8D2B';
          },
          getTickColor: (value: number): string => {
            return this.sliderTickColor(value, 4, 'A');
          },
          getLegend: (value: number): string => {
            return this.sliderLegend(value, 4, 'A');
          }
        },
        sliderAvpValue: 0,
        sliderRAvpValue: 0,
        sliderCurPriceValue: 0,
        manualRefresh: new EventEmitter<void>(),
      }, {
        id: 4,
        label: 'B',
        name: 'Mitteldreher',
        pzn: 0,
        percentage: 0,
        change: 0,
        averageavp: 0,
        averageravp: 0,
        averagenew: 0,
        oldprice: 0,
        lowestravp: 0,
        highestravp: 0,
        turnoverold: 0,
        turnovernew: 0,
        ticksArray: [],
        display: false,
        slideroptions: {
          floor: -20,
          ceil: 20,
          step: 0.1,
          showTickValues: true,
          hideLimitLabels: true,
          hidePointerLabels: true,
          /*    showSelectionBar: true,
              showSelectionBarFromValue: 0, */
          ticksArray: [0, 1, 2, 3],
          getPointerColor: (value: number): string => {
            return '#4C8D2B';
          },
          getSelectionBarColor: (minValue: number, maxValue?: number): string => {
            return '#4C8D2B';
          },
          getTickColor: (value: number): string => {
            return this.sliderTickColor(value, 4, 'B');
          },
          getLegend: (value: number): string => {
            return this.sliderLegend(value, 4, 'B');
          }
        },
        sliderAvpValue: 0,
        sliderRAvpValue: 0,
        sliderCurPriceValue: 0,
        manualRefresh: new EventEmitter<void>(),
      }, {
        id: 4,
        label: 'C',
        name: 'Langsamdreher',
        pzn: 0,
        percentage: 0,
        change: 0,
        averageavp: 0,
        averageravp: 0,
        averagenew: 0,
        oldprice: 0,
        lowestravp: 0,
        highestravp: 0,
        turnoverold: 0,
        turnovernew: 0,
        ticksArray: [],
        display: false,
        slideroptions: {
          floor: -20,
          ceil: 20,
          step: 0.1,
          showTickValues: true,
          hideLimitLabels: true,
          hidePointerLabels: true,
          /*    showSelectionBar: true,
              showSelectionBarFromValue: 0, */
          ticksArray: [0, 1, 2, 3],
          getPointerColor: (value: number): string => {
            return '#4C8D2B';
          },
          getSelectionBarColor: (minValue: number, maxValue?: number): string => {
            return '#4C8D2B';
          },
          getTickColor: (value: number): string => {
            return this.sliderTickColor(value, 4, 'C');
          },
          getLegend: (value: number): string => {
            return this.sliderLegend(value, 4, 'C');
          }
        },
        sliderAvpValue: 0,
        sliderRAvpValue: 0,
        sliderCurPriceValue: 0,
        manualRefresh: new EventEmitter<void>(),
      }]
    }];

  preisumfeldInit(): void {

  }

  sliderValueChange(changeContext: ChangeContext, cluster: any): void {

    cluster.change = changeContext.value;

    // für hauptcluster
    if (cluster.subclusters && cluster.subclusters.length > 0) {
      for (const prd of this.productData) {
        if (prd.PE_Cluster === cluster.id) {
          prd.finalPrice = prd.basePrice * (1 + changeContext.value / 100);
          /*if (prd.finalPrice > this.grenzwert) {
            prd.finalPrice = prd.AVP;
          }*/
          prd.truePrice = prd.finalPrice;
        }
      }
      for (const subcluster of cluster.subclusters) {
        subcluster.change = cluster.change;
      }
    } else {
      for (const prd of this.productData) {
        if (prd.PE_Cluster === cluster.id && prd.abc === cluster.label) {
          prd.finalPrice = prd.basePrice * (1 + changeContext.value / 100);
          /*if (prd.finalPrice > this.grenzwert) {
            prd.finalPrice = prd.AVP;
          }*/
          prd.truePrice = prd.finalPrice;
        }
      }
    }

    this.initData();
    this.updateClusterStats();
  }

  /* show and hide info table */
  toggleInfo(cluster: number): void {
    this.pecluster[cluster].display = !this.pecluster[cluster].display;
  }

  constructor(protected ar: ActivatedRoute, readonly http: HttpClient) {
    if (this.ar.snapshot.params.country) {
      if (this.ar.snapshot.params.country === 'AT') {
        this.country = 'AT';
        this.pecluster = this.peclusterat;
      } else {
        this.pecluster = this.peclusterde;
        this.country = 'DE';
      }
    }
    this.pharmacyId = this.ar.snapshot.params.id;
    if (this.ar.snapshot.params.category) {
      this.warengruppe = this.ar.snapshot.params.category;
    }

    for (const pharmacy of pharmacydata) {
      if (pharmacy.crmId == this.pharmacyId) {
        this.pharmacy = pharmacy;

      }
    }


    console.log('Umfeld ' + this.umfeld);
    console.log('Umfeld ' + this.umfeld);
    this.http.get('/assets/dummydata/' + this.pharmacyId + '.json').subscribe(res => {
      this.productData = res;
      this.productData = this.productData.filter((obj: any) => obj.Warengruppe === this.warengruppe);

      if (this.umfeld === 'avp') {
        for (const prod of this.productData) {
          prod.finalPrice = prod.AVP;
          prod.basePrice = prod.AVP;
          prod.truePrice = prod.AVP;
        }
      } else {
        for (const prod of this.productData) {
          prod.finalPrice = prod.price;
          prod.basePrice = prod.price;
          prod.truePrice = prod.price;
        }
      }
      for (const prod of this.productData) {
        const rnd = Math.random();
        if (rnd > 0.94) {
          prod.excludedprice = true;
        }
        if (rnd < 0.07) {
          prod.permanentprice = true;
        }
        if (rnd > 0.5 && rnd < 0.56) {
          prod.ownprice = true;
        }
      }

      this.initData();
      this.updateClusterStats();
    });
  }


  updateClusterStats(): void {
    console.log('updating cluster stats');
    for (const cl of this.pecluster) {
      let newPriceSum = 0;
      let counter = 0;
      let turnovernew = 0;
      for (const prd of this.productData) {
        if (prd.PE_Cluster === cl.id) {
          newPriceSum += prd.finalPrice;
          turnovernew += prd.units * prd.finalPrice;
          counter += 1;
        }
      }
      cl.averagenew = newPriceSum / counter;
      cl.turnovernew = turnovernew;

      if (this.umfeld === 'avp') {
        cl.sliderAvpValue = (cl.averageavp - cl.averageavp) / cl.averageavp * 100;
        cl.sliderRAvpValue = (cl.averageravp - cl.averageavp) / cl.averageavp * 100;
        cl.sliderCurPriceValue = (cl.oldprice - cl.averageavp) / cl.averageavp * 100;
        const optionsavp = Object.assign({}, cl.slideroptions);
        optionsavp.ticksArray = [0, cl.sliderAvpValue, cl.sliderRAvpValue, cl.sliderCurPriceValue];
        cl.slideroptions = Object.assign({}, optionsavp);
        console.log(cl);
        cl.manualRefresh.emit();
      } else {
        cl.sliderAvpValue = (cl.averageavp - cl.averageravp) / cl.averageravp * 100;
        cl.sliderRAvpValue = (cl.averageravp - cl.averageravp) / cl.averageravp * 100;
        cl.sliderCurPriceValue = (cl.oldprice - cl.averageravp) / cl.averageravp * 100;
        const optionsravp = Object.assign({}, cl.slideroptions);
        optionsravp.ticksArray = [0, cl.sliderAvpValue, cl.sliderRAvpValue, cl.sliderCurPriceValue];
        cl.slideroptions = Object.assign({}, optionsravp);
        console.log(cl);
        cl.manualRefresh.emit();
      }
      cl.hide = false;

      if (cl.subclusters && cl.subclusters.length > 0) {
        for (const subcluster of cl.subclusters) {
          console.log('drin');
          let newPriceSumSc = 0;
          let counterSc = 0;
          let turnovernewSc = 0;
          for (const prd of this.productData) {
            if (prd.PE_Cluster === subcluster.id && prd.abc === subcluster.label) {
              newPriceSumSc += prd.finalPrice;
              turnovernewSc += prd.units * prd.finalPrice;
              counterSc += 1;
            }
          }
          subcluster.averagenew = newPriceSumSc / counterSc;
          subcluster.turnovernew = turnovernewSc;

          if (this.umfeld === 'avp') {
            subcluster.sliderAvpValue = (subcluster.averageavp - subcluster.averageavp) / subcluster.averageavp * 100;
            subcluster.sliderRAvpValue = (subcluster.averageravp - subcluster.averageavp) / subcluster.averageavp * 100;
            subcluster.sliderCurPriceValue = (subcluster.oldprice - subcluster.averageavp) / subcluster.averageavp * 100;
            const optionsavp = Object.assign({}, subcluster.slideroptions);
            optionsavp.ticksArray = [0, subcluster.sliderAvpValue, subcluster.sliderRAvpValue, subcluster.sliderCurPriceValue];
            cl.slideroptions = Object.assign({}, optionsavp);
            console.log(subcluster);
            subcluster.manualRefresh.emit();
          } else {
            subcluster.sliderAvpValue = (subcluster.averageavp - subcluster.averageravp) / subcluster.averageravp * 100;
            subcluster.sliderRAvpValue = (subcluster.averageravp - subcluster.averageravp) / subcluster.averageravp * 100;
            subcluster.sliderCurPriceValue = (subcluster.oldprice - subcluster.averageravp) / subcluster.averageravp * 100;
            const optionsravp = Object.assign({}, subcluster.slideroptions);
            optionsravp.ticksArray = [0, subcluster.sliderAvpValue, subcluster.sliderRAvpValue, subcluster.sliderCurPriceValue];
            subcluster.slideroptions = Object.assign({}, optionsravp);
            console.log(subcluster);
            subcluster.manualRefresh.emit();
          }
          subcluster.hide=false;
        }
      }
    }
  }

// valueChanged(e: any, index: number): void {
//     if (index === -1) {
//       this.aclusterchange = e.target.value;
//       /* for (const c of this.peclusterat) {
//         c.change = e.target.value;
//       } **/
//       for (const prd of this.productData) {
//         prd.finalPrice = prd.basePrice * (1 + e.target.value / 100);
//         prd.truePrice = prd.finalPrice;
//       }
//
//     } else {
//       this.pecluster[index].change = e.target.value;
//       for (const prd of this.productData) {
//         if (prd.PE_Cluster === index) {
//           prd.finalPrice = prd.basePrice * (1 + e.target.value / 100);
//           prd.truePrice = prd.finalPrice;
//         }
//       }
//     }
//
//     this.initData();
//     this.updateClusterStats();
//   }


  initData(): void {
    this.pznCount = this.productData.length;
    this.packages = 0;
    this.turnoverOld = 0;
    this.turnoverNew = 0;

    let oldpricesum = 0;
    let ravpsum = 0;
    let avpsum = 0;
    let highravpsum = 0;
    let lowravpsum = 0;
    let truepricesum = 0;

    // KPI calculation turnovers
    for (const prod of this.productData) {
      this.packages += prod.units;
      oldpricesum += prod.pricebefore;
      ravpsum += prod.rprice;
      avpsum += prod.AVP;
      highravpsum += prod.rhighest;
      lowravpsum += parseFloat(prod.rlowest);

      truepricesum += prod.finalPrice;


      this.turnoverOld += prod.price * prod.units;
      if (prod.finalPrice) {
        this.turnoverNew += prod.finalPrice * prod.units;
      } else {
        this.turnoverNew += prod.truePrice * prod.units;
      }
    }

    this.productStatistics.avpCurrent = avpsum / this.productData.length;
    this.productStatistics.avpOld = this.productStatistics.avpCurrent * 0.98;
    this.productStatistics.highestRavp = highravpsum / this.productData.length;
    this.productStatistics.lowestRavp = lowravpsum / this.productData.length;
    this.productStatistics.ravpCurrent = ravpsum / this.productData.length;
    this.productStatistics.ravpOld = this.productStatistics.ravpCurrent * 0.96;
    this.productStatistics.trueprice = truepricesum / this.productData.length;
    this.truepriceStat = this.productStatistics.trueprice;

    // Cluster turnovers and product count
    for (let i = 0; i < 4; i++) {
      const c = this.pecluster[i];
      c.hide = true;
      const clusterdata = this.productData.filter((t: any) => t.PE_Cluster === c.id);
      let clusterTurnover = 0;
      let clusterTurnoverOld = 0;
      for (const prod of clusterdata) {
        clusterTurnover += prod.units * prod.finalPrice;
        clusterTurnoverOld += prod.units * prod.price;
      }

      c.pzn = clusterdata.length;
      c.percentage = clusterTurnover / this.turnoverNew * 100;
      c.turnoverold = clusterTurnoverOld;
      c.turnovernew = clusterTurnover;
      console.log('initializing cluster ' + i);
      console.log(c);
      if (c.subclusters && c.subclusters.length > 0) {
        for (const subcluster of c.subclusters) {
          subcluster.hide = true;
          const subclusterdata = this.productData.filter((t: any) => t.PE_Cluster === c.id && t.abc === subcluster.label);
          //console.log('initializing subcluster ' + c.id + '.' + subcluster.label);
          //console.log(subclusterdata);
          let subclusterTurnover = 0;
          let subclusterTurnoverOld = 0;
          for (const prod of subclusterdata) {
            subclusterTurnover += prod.units * prod.finalPrice;
            subclusterTurnoverOld += prod.units * prod.price;
          }


          subcluster.pzn = subclusterdata.length;
          subcluster.percentage = subclusterTurnover / this.turnoverNew * 100;
          subcluster.turnoverold = subclusterTurnoverOld;
          subcluster.turnovernew = subclusterTurnover;
          console.log(subcluster);
        }
      }

    }

    // Cluster initialisieren
    for (const cl of this.pecluster) {
      let avpsum = 0;
      let ravpsum = 0;
      let oldpricesum = 0;
      let ravplowestsum = 0;
      let ravphighestsum = 0;
      let newpricesum = 0;
      let counter = 0;
      let turnoverold = 0;
      let turnovernew = 0;
      for (const prd of this.productData) {
        if (prd.PE_Cluster === cl.id) {

          avpsum += prd.AVP;
          ravpsum += prd.rprice;
          oldpricesum += prd.price;

          ravplowestsum += parseInt(prd.rmin);
          ravphighestsum += prd.rmax;
          newpricesum += prd.finalPrice;
          turnoverold += prd.units * prd.price;
          turnovernew += prd.units * prd.finalPrice;
          counter++;
        }
      }
      cl.averageavp = avpsum / counter;
      cl.averageravp = ravpsum / counter;
      cl.averagenew = newpricesum / counter;
      cl.oldprice = oldpricesum / counter;
      cl.lowestravp = ravplowestsum / counter;
      cl.highestravp = ravphighestsum / counter;
      cl.turnoverold = turnoverold;
      cl.turnovernew = turnovernew;

      if (this.umfeld == 'avp') {
        cl.sliderValue = (cl.averageavp - cl.averageavp) / cl.averageavp * 100;
        cl.sliderRAvpValue = (cl.averageravp - cl.averageavp) / cl.averageavp * 100;
        cl.sliderCurPriceValue = (cl.oldprice - cl.averageavp) / cl.averageavp * 100;
        cl.slideroptions.ticksArray = [0, cl.sliderAvpValue, cl.sliderRAvpValue, cl.sliderCurPriceValue];
      } else {

        cl.sliderValue = (cl.averageravp - cl.oldprice) / cl.oldprice * 100;
        cl.sliderRAvpValue = (cl.averageravp - cl.oldprice) / cl.oldprice * 100;
        cl.sliderValue = (cl.averageavp - cl.averageavp) / cl.averageavp * 100;
        cl.sliderCurPriceValue = (cl.oldprice - cl.oldprice) / cl.oldprice * 100;
        cl.slideroptions.ticksArray = [0, cl.sliderAvpValue, cl.sliderRAvpValue, cl.sliderCurPriceValue];
      }

      const options = Object.assign({}, cl.slideroptions);
      options.ticksArray = [0, cl.sliderAvpValue, cl.sliderRAvpValue, cl.sliderCurPriceValue];

      cl.slideroptions = Object.assign({}, options);

      cl.manualRefresh.emit();

      console.log('cluster ' + cl.id + ' slider values - avp ' + cl.sliderAvpValue + ' / ravp ' + cl.sliderRAvpValue + ' / oldPrice ' + cl.sliderCurPriceValue);

      console.log(cl.id + ' / oldpricesum ' + oldpricesum + ' / tpsum ' + newpricesum);
      console.log(cl);

      if (cl.subclusters && cl.subclusters.length > 0) {
        for (let subcluster of cl.subclusters) {
          subcluster.hide=true;
          avpsum = 0;
          ravpsum = 0;
          oldpricesum = 0;
          ravplowestsum = 0;
          ravphighestsum = 0;
          newpricesum = 0;
          counter = 0;
          turnoverold = 0;
          turnovernew = 0;
          for (const prd of this.productData) {
            if (prd.PE_Cluster === cl.id && prd.abc === subcluster.label) {

              avpsum += prd.AVP;
              ravpsum += prd.rprice;
              oldpricesum += prd.price;

              ravplowestsum += parseInt(prd.rmin);
              ravphighestsum += prd.rmax;
              newpricesum += prd.finalPrice;
              turnoverold += prd.units * prd.price;
              turnovernew += prd.units * prd.finalPrice;
              counter++;
            }
          }
          subcluster.averageavp = avpsum / counter;
          subcluster.averageravp = ravpsum / counter;
          subcluster.averagenew = newpricesum / counter;
          subcluster.oldprice = oldpricesum / counter;
          subcluster.lowestravp = ravplowestsum / counter;
          subcluster.highestravp = ravphighestsum / counter;
          subcluster.turnoverold = turnoverold;
          subcluster.turnovernew = turnovernew;

          if (this.umfeld == 'avp') {
            subcluster.sliderValue = (subcluster.averageavp - subcluster.averageavp) / subcluster.averageavp * 100;
            subcluster.sliderRAvpValue = (subcluster.averageravp - subcluster.averageavp) / subcluster.averageavp * 100;
            subcluster.sliderCurPriceValue = (subcluster.oldprice - subcluster.averageavp) / subcluster.averageavp * 100;
            subcluster.slideroptions.ticksArray = [0, subcluster.sliderAvpValue, subcluster.sliderRAvpValue, subcluster.sliderCurPriceValue];
          } else {

            subcluster.sliderValue = (subcluster.averageravp - subcluster.oldprice) / subcluster.oldprice * 100;
            subcluster.sliderRAvpValue = (subcluster.averageravp - subcluster.oldprice) / subcluster.oldprice * 100;
            subcluster.sliderValue = (subcluster.averageavp - subcluster.averageavp) / subcluster.averageavp * 100;
            subcluster.sliderCurPriceValue = (subcluster.oldprice - subcluster.oldprice) / subcluster.oldprice * 100;
            subcluster.slideroptions.ticksArray = [0, subcluster.sliderAvpValue, subcluster.sliderRAvpValue, subcluster.sliderCurPriceValue];
          }

          const soptions = Object.assign({}, subcluster.slideroptions);
          soptions.ticksArray = [0, subcluster.sliderAvpValue, subcluster.sliderRAvpValue, subcluster.sliderCurPriceValue];

          subcluster.slideroptions = Object.assign({}, soptions);
        }
      }

    }

  }

  ngOnInit(): void {


  }

  proceedClick(): void {
    if (this.step < 5) {
      this.step += 1;
      this.progress.nativeElement.value = (this.step-1)*25;
      console.log("progress bar value " + this.progress.nativeElement.value);
    }
    this.filterCluster = '';
    window.scroll(0, 0);
  }

  backClick(): void {
    if (this.step > 1) {
      this.step -= 1;
      this.progress.nativeElement.value= (this.step-1)*25;
      console.log("progress bar value " + this.progress.nativeElement.value);
    } else {
      window.history.back();
    }
    window.scroll(0, 0);
  }

  setProcessStep(step: number) {
    this.step = step;
    this.progress.nativeElement.value = (step-1)*25;
    window.scroll(0, 0);

  }

  umfeldChanged(): void {
    if (this.umfeld === 'avp') {
      for (const prod of this.productData) {
        prod.finalPrice = prod.AVP;
        prod.basePrice = prod.AVP;
        prod.truePrice = prod.AVP;
      }
    } else {
      for (const prod of this.productData) {
        prod.finalPrice = prod.price;
        prod.basePrice = prod.price;
        prod.truePrice = prod.price;
      }
    }
    this.initData();
    this.updateClusterStats();
  }


  closeModal(): void {
    this.showModal = 'none';
  }

  onSubmit(f: NgForm): void {
    this.proceedClick();
  }

  showClusterPriceList(cluster: number): void {
    this.filterCluster = '' + cluster;
    this.step++;
    /*this.selectedCluster = cluster;
    this.clusterProductData = this.productData.filter((t: any) => t.PE_Cluster === cluster);
    this.showModal = 'inline'; */
  }

  rundungChanged(): void {
    switch (this.rundung) {
      case 'rnd5000':
        for (const prod of this.productData) {
          prod.finalPrice = Math.ceil(prod.truePrice * 2) / 2;
        }
        break;
      case 'rnd4999':
        for (const prod of this.productData) {
          prod.finalPrice = Math.ceil(prod.truePrice * 2) / 2 - 0.01;
        }
        break;
      case 'rndungerade':
        for (const prod of this.productData) {
          prod.finalPrice = Math.ceil((prod.truePrice - 0.01) * 50) / 50 + 0.01;
        }
        break;
      case 'rndarznei':
        for (const prod of this.productData) {
          prod.finalPrice = Math.ceil(prod.truePrice * 20) / 20;
        }
        break;
      case 'rndlast':
        console.log('drin ' + this.lastDigit);
        const digit = parseInt(this.lastDigit);
        console.log('Letzte Zahl: ' + digit);
        for (const prod of this.productData) {
          prod.finalPrice = (Math.ceil((prod.truePrice * 100 - digit) / 10) * 10 + digit) / 100;
        }
        break;
      case 'keine':
        for (const prod of this.productData) {
          prod.finalPrice = prod.truePrice;
        }
        break;
      default:
        for (const prod of this.productData) {
          prod.finalPrice = prod.truePrice;
        }
    }
    this.initData();
  }

  startWorkflow(): void {
    if (window.confirm('Möchten Sie die Preiskonfiguration in den Freigabeworkflow übergeben?') == true) {
      this.step++;
      this.progress.nativeElement.value = 100;
    }
  }

  sliderLegend(value: number, cluster: number, subcluster: string): string {
    let myCluster;
    myCluster = this.pecluster.find((t: any) => t.id === cluster);

    if (subcluster !== '') {
      myCluster = myCluster.subclusters.find((s: any) => s.label === subcluster);
    }

    if (!myCluster) {
      console.log('error finding cluster ' + cluster + ' ' + subcluster);
    }
    switch (value) {
      case myCluster.sliderAvpValue:
        return 'A';
        break;
      case myCluster.sliderRAvpValue:

        return 'P';
        break;
      case myCluster.sliderCurPriceValue:
        return 'U';
        break;
    }
    return '';
  }

  sliderTickColor(value: number, cluster: number, subcluster: string): string {
    console.log('sliderTickColor for cluster ' + cluster + ' / value = ' + value);

    let myCluster;
    myCluster = this.pecluster.find((t: any) => t.id === cluster);

    if (subcluster !== '') {
      myCluster = myCluster.subclusters.find((s: any) => s.label === subcluster);
    }

    if (!myCluster) {
      console.log('error finding cluster ' + cluster + ' ' + subcluster);
    }
    console.log(myCluster);
    console.log(myCluster.sliderAvpValue + ' / ' + myCluster.sliderRAvpValue + ' / ' + myCluster.sliderCurPriceValue);
    switch (value) {
      case myCluster.sliderAvpValue:
        return '#AEC8E5';
        break;
      case myCluster.sliderRAvpValue:
        return '#868686';
        break;
      case myCluster.sliderCurPriceValue:
        return '#BF7067';
        break;
    }
    return '';
  }

  toggleSubcluster(cluster: any) {
    cluster.showSubcluster = !cluster.showSubcluster;
  }

  updateGrenzwerte(): void {
    for (const prod of this.productData) {
      if (prod.finalPrice > prod.AVP) {
        prod.finalPrice = prod.AVP;
      }
    }
  }

}
