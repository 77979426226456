import {Component, Input, OnInit} from '@angular/core';
//import productList from '../../../../assets/dummydata/productdata.json';
import {OrderModule} from 'ngx-order-pipe';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-truepricetable',
  templateUrl: './truepricetable.component.html',
  styleUrls: ['./truepricetable.component.css']
})
export class TruepricetableComponent implements OnInit {
  @Input() set productData(list: any) {
    this.productList = list;
    this.originalList = list;
    console.log("Liste");
    console.log(this.productList);
  }
  @Input() showFilter = false;
  @Input() showFinalPrice = false;
  @Input() showTruePrice = false;
  @Input() country = 'DE';
  private privfilterCat = 'Kategorie';
  productList = [] as any;
  originalList = [] as any;

  @Input() set filterCat(value: string) {
    this.privfilterCat = value;
    this.filterList();
  }

  get filterCat(): string {
    return this.privfilterCat;
  }

  private privfilterCluster = '';
  @Input() set filterCluster(value: string) {
    this.privfilterCluster = value;
    this.filterList();
  }

  get filterCluster(): string {
    return this.privfilterCluster;
  }

  order = 'Produkt';
  reverse = false;

  clusters = <any>[];

  clustersDE = [{value: 0, text: 'Hohe Preiselastizität'},
    {value: 1, text: 'Mittlere Preiselastizität'},
    {value: 2, text: 'Niedrige Preiselastizität'},
    {value: 4, text: 'Keine Preiselastizität'}
  ];

  clustersAT = [{value: '0', text: 'Hohe Preiselastizität'},
    {value: '0A', text: 'Hohe Preiselastizität - Schnelldreher'},
    {value: '0B', text: 'Hohe Preiselastizität - Mitteldreher'},
    {value: '0C', text: 'Hohe Preiselastizität - Langsamdreher'},
    {value: '1', text: 'Mittlere Preiselastizität'},
    {value: '1A', text: 'Mittlere Preiselastizität - Schnelldreher'},
    {value: '1B', text: 'Mittlere Preiselastizität - Mitteldreher'},
    {value: '1C', text: 'Mittlere Preiselastizität - Langsamdreher'},
    {value: '2', text: 'Niedrige Preiselastizität'},
    {value: '2A', text: 'Niedrige Preiselastizität - Schnelldreher'},
    {value: '2B', text: 'Niedrige Preiselastizität - Mitteldreher'},
    {value: '2C', text: 'Niedrige Preiselastizität - Langsamdreher'},
    {value: '4', text: 'Keine Preiselastizität'},
    {value: '4A', text: 'Keine Preiselastizität - Schnelldreher'},
    {value: '4B', text: 'Keine Preiselastizität - Mitteldreher'},
    {value: '4C', text: 'Keine Preiselastizität - Langsamdreher'}];
  filterClass = '';
  filterProd = '';

  filterProducer = [{name: 'Hersteller', val: ''}, {name: '1 A Pharma GmbH', val: '7197'},
    {name: '1001 Artikel Medical G', val: '4509'},
    {name: '4 CARE GmbH', val: '17539'},
    {name: 'Abanta Pharma GmbH', val: '8942'},
    {name: 'Alcon Pharma GmbH', val: '9454'},
    {name: 'ALIUD Pharma GmbH', val: '387'},
    {name: 'Allergan GmbH', val: '10428'},
    {name: 'ALLERGIKA Pharma GmbH', val: '13545'},
    {name: 'Almased Wellness GmbH', val: '1008'},
    {name: 'ALMIRALL HERMAL GmbH', val: '11760'},
    {name: 'Andreas Jubin Pharma V', val: '14081'},
    {name: 'Angelini Pharma Deutsc', val: '10794'},
    {name: 'ANGELINI Pharma Österr', val: '5928'},
    {name: 'apomix AMH Niemann Gmb', val: '48307'},
    {name: 'Ardeypharm GmbH', val: '855'},
    {name: 'Aristo Pharma GmbH', val: '17760'},
    {name: 'Ascensia Diabetes Care', val: '8741'},
    {name: 'AURICA Naturheilm.u.Na', val: '1170'},
    {name: 'Avitale GmbH', val: '19658'},
    {name: 'axicorp Pharma GmbH', val: '1498'},
    {name: 'B. Braun Melsungen AG', val: '3502'},
    {name: 'Bayer Vital GmbH GB Ph', val: '2530'},
    {name: 'Bayer Vital GmbH', val: '6410'},
    {name: 'Becton Dickinson GmbH', val: '71378'},
    {name: 'Beiersdorf AG Eucerin', val: '4472'},
    {name: 'Beiersdorf AG', val: '2750'},
    {name: 'bene Arzneimittel GmbH', val: '2800'},
    {name: 'Benevi Med GmbH & Co. ', val: '5078'},
    {name: 'BERAGENA Arzneimittel ', val: '2850'},
    {name: 'BERLIN-CHEMIE AG', val: '48010'},
    {name: 'betapharm Arzneimittel', val: '3025'},
    {name: 'Biologische Heilmittel', val: '36260'},
    {name: 'Bionorica SE', val: '3320'},
    {name: 'Boehringer Ingelheim V', val: '3755'},
    {name: 'Bombastus-Werke AG', val: '48560'},
    {name: 'Certmedica Internation', val: '7411'},
    {name: 'Chem. Fabrik Kreussler', val: '16110'},
    {name: 'CHEPLAPHARM Arzneimitt', val: '4861'},
    {name: 'CNP Pharma GmbH', val: '4291'},
    {name: 'CODAN pvb Medical GmbH', val: '5018'},
    {name: 'CP GABA GmbH', val: '7093'},
    {name: 'Cumdente GmbH', val: '3976'},
    {name: 'Dallmanns Pharma Candy', val: '5540'},
    {name: 'DERMAPHARM AG', val: '5970'},
    {name: 'Dexcel Pharma GmbH', val: '277'},
    {name: 'DHU-Arzneimittel GmbH ', val: '36245'},
    {name: 'Dr. August Wolff GmbH ', val: '35050'},
    {name: 'Dr. Ausbüttel & Co. Gm', val: '36470'},
    {name: 'Dr. C. SOLDAN GmbH', val: '27630'},
    {name: 'Dr. Gerhard Mann', val: '18670'},
    {name: 'Dr. Junghans Medical G', val: '48325'},
    {name: 'DR. KADE Pharmazeutisc', val: '14331'},
    {name: 'Dr. Pfleger Arzneimitt', val: '22860'},
    {name: 'Dr. Theiss Naturwaren ', val: '31207'},
    {name: 'Dr. Winzer Pharma GmbH', val: '34860'},
    {name: 'Dr.Hobein (Nachf.) Gmb', val: '12270'},
    {name: 'Dr.Willmar Schwabe Gmb', val: '36410'},
    {name: 'Eduard Gerlach GmbH', val: '9450'},
    {name: 'Engelhard Arzneimittel', val: '7520'},
    {name: 'Equimedis Dr. Jacoby G', val: '13770'},
    {name: 'Essity Germany GmbH', val: '6311'},
    {name: 'Essity Germany GmbH', val: '6312'},
    {name: 'EurimPharm Arzneimitte', val: '72670'},
    {name: 'Fagron GmbH & Co. KG', val: '87240'},
    {name: 'FaroDerm GmbH', val: '6786'},
    {name: 'FERRING Arzneimittel G', val: '8170'},
    {name: 'Fresenius Kabi Deutsch', val: '127'},
    {name: 'Froximun AG', val: '1869'},
    {name: 'G. Pohl-Boskamp GmbH &', val: '23660'},
    {name: 'Galderma Laboratorium ', val: '9144'},
    {name: 'GALENpharma GmbH', val: '23085'},
    {name: 'GEHE Pharma Handel Gmb', val: '72917'},
    {name: 'gepepharm GmbH', val: '9413'},
    {name: 'GlaxoSmithKline Consum', val: '8250'},
    {name: 'Gothaplast GmbH', val: '48140'},
    {name: 'Hecht-Pharma GmbH', val: '10812'},
    {name: 'Heilerde-Gesellschaft ', val: '18180'},
    {name: 'Hennig Arzneimittel Gm', val: '11590'},
    {name: 'HERMES Arzneimittel Gm', val: '11780'},
    {name: 'HEUMANN PHARMA GmbH & ', val: '11950'},
    {name: 'Hexal AG', val: '11965'},
    {name: 'Hirundo Products', val: '2913'},
    {name: 'Hofmann & Sommer GmbH ', val: '48467'},
    {name: 'Homöopathisches Labora', val: '22890'},
    {name: 'HRA Pharma Deutschland', val: '4475'},
    {name: 'Hübner Naturarzneimitt', val: '6082'},
    {name: 'Humana Vertriebs GmbH', val: '10457'},
    {name: 'INFECTOPHARM Arzn.u.Co', val: '13590'},
    {name: 'INNOMEDIS AG', val: '6938'},
    {name: 'Innosan GmbH', val: '4335'},
    {name: 'Johannes Bürger Ysatfa', val: '35510'},
    {name: 'Johnson & Johnson GmbH', val: '10670'},
    {name: 'Johnson & Johnson GmbH', val: '4102'},
    {name: 'JUNEK Europ-Vertrieb G', val: '14094'},
    {name: 'Karo Pharma GmbH', val: '10218'},
    {name: 'Kattwiga Arzneimittel ', val: '36300'},
    {name: 'Kaymogyn GmbH', val: '5089'},
    {name: 'KESLA PHARMA WOLFEN GM', val: '48447'},
    {name: 'Klinge Pharma GmbH', val: '7283'},
    {name: 'kohlpharma GmbH', val: '15685'},
    {name: 'KSK-Pharma Vertriebs A', val: '16430'},
    {name: 'Kyberg Pharma Vertrieb', val: '1367'},
    {name: 'Laboratoires Diepharme', val: '1821'},
    {name: 'Laboratoires Noreva Gm', val: '5854'},
    {name: 'Lansinoh Laboratories ', val: '2351'},
    {name: 'lebewohl-Fabrik GmbH &', val: '17430'},
    {name: 'LEYH-PHARMA GmbH', val: '48225'},
    {name: 'LifeScan Deutschland G', val: '9652'},
    {name: 'LOreal Deutschland Gmb', val: '16380'},
    {name: 'LOreal Deutschland Gmb', val: '32677'},
    {name: 'MCM KLOSTERFRAU Vertr.', val: '15350'},
    {name: 'MEDA Pharma GmbH & Co.', val: '928'},
    {name: 'Medac GmbH', val: '19083'},
    {name: 'medesign I. C. GmbH', val: '19180'},
    {name: 'MEDICE Arzneimittel Pü', val: '19130'},
    {name: 'Medicom Pharma GmbH', val: '19117'},
    {name: 'MIBE GmbH Arzneimittel', val: '19600'},
    {name: 'MibeTec GmbH', val: '9181'},
    {name: 'MICKAN Arzneimittel Gm', val: '19650'},
    {name: 'Midro Lörrach GmbH', val: '19655'},
    {name: 'MUNDIPHARMA GmbH', val: '20347'},
    {name: 'Mylan Healthcare GmbH', val: '27662'},
    {name: 'Nelsons GmbH', val: '1982'},
    {name: 'Niehaus Pharma GmbH & ', val: '6411'},
    {name: 'Norgine GmbH', val: '21080'},
    {name: 'NOWEDA Apothekergenoss', val: '7171'},
    {name: 'OHROPAX GmbH', val: '21620'},
    {name: 'Omega Pharma Deutschla', val: '5896'},
    {name: 'OmniVision GmbH', val: '2027'},
    {name: 'OPTIMA Pharmazeutische', val: '21800'},
    {name: 'Orthomol pharmazeutisc', val: '31870'},
    {name: 'P.W. Beyvers GmbH', val: '3035'},
    {name: 'P&G Health Germany Gmb', val: '19418'},
    {name: 'P&M COSMETICS GmbH & C', val: '5906'},
    {name: 'Pädia GmbH', val: '7779'},
    {name: 'Pari GmbH', val: '1013'},
    {name: 'PAUL HARTMANN AG', val: '36750'},
    {name: 'Pfizer Consumer Health', val: '20050'},
    {name: 'Pfizer Pharma PFE GmbH', val: '8229'},
    {name: 'Pharma Peter GmbH', val: '22751'},
    {name: 'PHARMA STULLN GmbH', val: '23233'},
    {name: 'PharmaSGP GmbH', val: '4674'},
    {name: 'PIERRE FABRE DERMO KOS', val: '11309'},
    {name: 'PIERRE FABRE DERMO KOS', val: '8066'},
    {name: 'Pierre Fabre Pharma Gm', val: '25922'},
    {name: 'pro medico GmbH', val: '4638'},
    {name: 'Procter & Gamble GmbH', val: '25685'},
    {name: 'Protina Pharmazeutisch', val: '23860'},
    {name: 'PUREN Pharma GmbH & Co', val: '574'},
    {name: 'Queisser Pharma GmbH &', val: '14380'},
    {name: 'Quiris Healthcare GmbH', val: '2136'},
    {name: 'ratiopharm GmbH', val: '25137'},
    {name: 'RAUSCH (Deutschland) G', val: '7078'},
    {name: 'Reckitt Benckiser Deut', val: '25245'},
    {name: 'Recordati Pharma GmbH', val: '19430'},
    {name: 'REPHA GmbH Biologische', val: '36370'},
    {name: 'Resana GmbH', val: '25551'},
    {name: 'RETORTA GmbH', val: '25570'},
    {name: 'Retterspitz GmbH & Co.', val: '28460'},
    {name: 'RIEMSER Pharma GmbH', val: '22345'},
    {name: 'Roche Diabetes Care De', val: '8041'},
    {name: 'SALUS Pharma GmbH', val: '6495'},
    {name: 'SANAVITA Pharmaceutica', val: '9326'},
    {name: 'Sanofi-Aventis Deutsch', val: '8822'},
    {name: 'SANUM-KEHLBECK GmbH & ', val: '27035'},
    {name: 'SCHAPER & BRÜMMER GmbH', val: '36400'},
    {name: 'SCHÜLKE & MAYR GmbH', val: '29237'},
    {name: 'Sebapharma GmbH & Co.K', val: '27220'},
    {name: 'Serumwerk Bernburg AG', val: '48110'},
    {name: 'Sidroga Gesellschaft f', val: '5258'},
    {name: 'Smith & Nephew GmbH', val: '4245'},
    {name: 'Spinnrad GmbH', val: '4438'},
    {name: 'STADA Consumer Health ', val: '7118'},
    {name: 'Südmedica GmbH', val: '27940'},
    {name: 'Synformulas GmbH', val: '7498'},
    {name: 'TAD Pharma GmbH', val: '31005'},
    {name: 'Teofarma s.r.l.', val: '31181'},
    {name: 'TePe D-A-CH GmbH', val: '31166'},
    {name: 'TRB Chemedica AG', val: '4765'},
    {name: 'Trommsdorff GmbH & Co.', val: '31510'},
    {name: 'TZMO Deutschland GmbH', val: '546'},
    {name: 'UCB Pharma GmbH', val: '32015'},
    {name: 'unizell Medicare GmbH', val: '32170'},
    {name: 'URSAPHARM Arzneimittel', val: '2960'},
    {name: 'Verla-Pharm Arzneimitt', val: '32610'},
    {name: 'Vielstedter Elektronik', val: '32683'},
    {name: 'WALA Heilmittel GmbH', val: '36430'},
    {name: 'WEBER & WEBER GmbH & C', val: '33000'},
    {name: 'WELEDA AG', val: '36440'},
    {name: 'WEPA Apothekenbedarf G', val: '34525'},
    {name: 'Wörwag Pharma GmbH & C', val: '34150'},
    {name: 'Zentiva Pharma GmbH', val: '17635'}] as any;
  filterProductClass = [{val: '', name: 'Produktklasse'}, {val: 'Freiwahl', name: 'Freiwahl'}, {val: 'Sichtwahl', name: 'Sichtwahl'}];
  filterCategory = ['Kategorie', 'Allergie',
    'Alternative Therapierichtungen',
    'Antiinfektiva und Impfstoffe',
    'Antineoplast. und Immunmodulatoren',
    'Asthma/COPD',
    'Augenpräparate',
    'Beruhigung & Schlaf',
    'Blut und blutbildende Organe',
    'Body Clean & Care',
    'Dekorative Kosmetik',
    'Diabetes Care',
    'Entwöhnungsmittel',
    'Erkältung',
    'Face Clean & Care',
    'Fußpflege',
    'Haarpflege',
    'Haut Haare Nägel',
    'Herz-Kreislauf. Cholesterin',
    'Hormone',
    'Intimpflege',
    'Kinder & Babypflege',
    'Magen & Verdauung',
    'Männerkosmetik',
    'Mund & Zahnbehandlung',
    'Mundhygiene',
    'Muskeln Gelenke inkl Schmerz',
    'Nährmittel',
    'Nervensystem sonst',
    'Ohrenpräparate',
    'Schlankheitsmittel',
    'Schmerzmittel exkl M',
    'Sonnenschutz',
    'Sonstige Hautprodukte',
    'Sonstige',
    'Sportlerprodukte',
    'Süßwaren',
    'Tee & Getränke',
    'Tonika & Allheilmittel',
    'Übelkeit & Schwindel',
    'unbekannt',
    'Urogenitale Mittel (Blase. Prostata. Hormone etc.)',
    'Verschiedenes',
    'Vitamine & Mineralstoffe',
    'Wundheilung'];

  constructor(readonly ar: ActivatedRoute) {
    if (ar.snapshot.params.country === 'DE') {
      this.clusters = this.clustersDE;
    } else {
      this.clusters = this.clustersAT;
    }
    console.log('pricelist: ' + this.filterCat);
  }

  filterList(): void {
    console.log('Filter ' + this.filterClass);
    this.productList = this.originalList;
    console.log(this.productData);
    if (this.filterClass !== '') {
      this.productList = this.productList.filter((t: any) => t.Warengruppe === this.filterClass);
    }
    if (this.filterCat !== 'Kategorie') {
      this.productList = this.productList.filter((t: any) => t.Subkat === this.filterCat);
    }
    if (this.filterProd !== '') {
      this.productList = this.productList.filter((t: any) => t.Hersteller_Id === parseInt(this.filterProd));
    }
    if (this.filterCluster !== '' && (this.country === 'DE' || this.filterCluster.length==1)) {
      console.log('filter ' + this.filterCluster);
      console.log(this.productData);
      this.productList = this.productList.filter((t: any) => t.PE_Cluster === parseInt(this.filterCluster));
    }
    if (this.filterCluster !== '' && this.country == 'AT' && this.filterCluster.length == 2) {
      const mainCluster = this.filterCluster[0];
      const subCluster = this.filterCluster[1];
      console.log('filter cluster ' + mainCluster + ' ' + subCluster);
      this.productList = this.productList.filter((t: any) => (t.PE_Cluster == parseInt(mainCluster) && t.abc == subCluster));
    }
  }

  pricebase(product: any): string {
    let result = 'AVP';
    if (product.ownprice) {
      result = 'Eigener Preis';
    }
    if (product.permanentprice) {
      result = 'Dauerpreis';
    }
    if (product.excludedprice) {
      result = '-';
    }
    return result;
  }

  tagprice(product: any): string {
    let tag = '';
    if (product.permanentprice) {
      tag = 'D';
    }
    if (product.ownprice) {
      tag = 'M';
    }
    if (product.excludedprice) {
      tag = 'N';
    }
    return tag;
  }

  tagLegend(product: any): string {
    let tag = '';
    if (product.permanentprice) {
      tag = 'Dauerpreis';
    }
    if (product.ownprice) {
      tag = 'Manuell gesetzter Preis';
    }
    if (product.excludedprice) {
      tag = 'Von True Price ausgeschlossen';
    }
    return tag;
  }


  ngOnInit(): void {
    //this.productList = this.productData;
    console.log(this.productData);

  }

  setOrder(value: string): void {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }

  getClassOf(val: number ):string {
    if (val > 0) {
      return 'pos';
    }
    if (val <0) {
      return 'neg';
    }
    return '';
  }


}
