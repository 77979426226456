import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-adminpricelist',
  templateUrl: './adminpricelist.component.html',
  styleUrls: ['./adminpricelist.component.css']
})
export class AdminpricelistComponent implements OnInit {

  category = 'Kategorie';

  constructor(protected ar: ActivatedRoute) {
    if (ar.snapshot.params.category) {
      console.log(ar.snapshot.params.category);
      this.category = ar.snapshot.params.category;
    }
  }
  ngOnInit(): void {
  }

}
