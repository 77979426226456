import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import pharmacydata from '../../../assets/dummydata/pharmacies.json';

@Component({
  selector: 'app-adminpharmacy',
  templateUrl: './adminpharmacy.component.html',
  styleUrls: ['./adminpharmacy.component.css']
})
export class AdminpharmacyComponent implements OnInit {

  constructor(protected router: Router, readonly ar: ActivatedRoute) {
      this.pharmacyId = this.ar.snapshot.params.id;
      for (const pharmacy of pharmacydata) {
        console.log(pharmacy.crmId + ' vs ' + this.pharmacyId);
        console.log(pharmacy.crmId == this.pharmacyId);
        if (pharmacy.crmId == this.pharmacyId) {
          console.log('found pharmacy');
          this.pharmacy = pharmacy;
          this.country = pharmacy.country;
        }
      }
  }

  pharmacyId = 0;
  pharmacy = <any>{};
  country = '';

  ngOnInit(): void {
  }

  backClick(): void {
    window.history.back();
  }
startSimulation(country: string, category: string): void {
    this.router.navigateByUrl('/admin/pharmacies/' + this.pharmacyId + '/simulation/' + country + '/' + category);
}
openPricelist(prodClass: string): void {
  this.router.navigateByUrl('/admin/pharmacies/' + this.pharmacyId + '/pricelist/' + prodClass);
}
  openHotfix(): void {
    this.router.navigateByUrl('/admin/pharmacies/' + this.pharmacyId + '/hotfix');
  }
  approve(): void {
    this.router.navigateByUrl('/admin/pharmacies/' + this.pharmacyId + '/approve');
  }
  upload(): void {
    this.router.navigateByUrl('/admin/pharmacies/' + this.pharmacyId + '/upload');
  }

}
