import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-pharmacayhomepage',
  templateUrl: './pharmacayhomepage.component.html',
  styleUrls: ['./pharmacayhomepage.component.css']
})
export class PharmacayhomepageComponent implements OnInit {

  constructor(protected router: Router) { }

  ngOnInit(): void {
  }

  backClick(): void {
    window.history.back();
  }
  startSimulation(): void {
    this.router.navigateByUrl('/pharmacy/pharmacies/12343234/simulation');
  }
  openPricelist(): void {
    this.router.navigateByUrl('/pharmacy/pharmacies/12343234/pricelist');
  }


}
