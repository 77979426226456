<div class="container-fluid mx-2 my-3 bg-white" *ngIf="showFilter">
  <div class="row">
    <div class="col-1 solvenabold">
      <i class="fa fa-filter"></i>
    </div>
    <div class="col-3">
      <select class="form-select form-select-sm " (change)="filterList()" [(ngModel)]="filterSpecial">
        <option value="">Alle Preise</option>
        <option value="D">Dauerpreise</option>
        <option value="M">Manuell gesetzter Preis</option>
        <option value="N">Ausschluss</option>
      </select>
    </div>

    <div class="col-3"><select class="form-select form-select-sm " [(ngModel)]="filterCat" (change)="filterList()">
      <option *ngFor="let cat of filterCategory" value='{{cat}}'>{{cat}}</option>
    </select></div>
    <div class="col-3"><select class="form-select form-select-sm " [(ngModel)]="filterProd" (change)="filterList()">
      <option *ngFor="let comp of filterProducer" value='{{comp.val}}'>{{comp.name}}</option>
    </select></div>
    <div class="col-2">Suche</div>
  </div>

</div>

<div class="mx-0">
  <table width="100%">
    <thead>
    <td (click)="setOrder('PZN')">PZN</td>
    <td (click)="setOrder('Produkt')">Produkt<br>
      <span class="smalltext" (click)="setOrder('Hersteller')">Hersteller</span>
    </td>
    <td align="right" class="" (click)="setOrder('AVP')">AVP<br>
      <span class="smalltext">Abweichung TP</span></td>
    <td align="right" class="bg-alt" (click)="setOrder('rprice')">Umfeldpreis &Oslash;<br>
      <span class="smalltext">Abweichung TP</span>
    </td>
    <td align="right" (click)="setOrder('price')">Aktueller Preis</td>
    <td></td>
    <td *ngIf="editMode"></td>
    <td align="center" *ngIf="editMode && country=='DE'"><span class="tag">D</span></td>
    <td align="center" *ngIf="editMode"><span class="tag">N</span></td>
    </thead>
    <tbody>
    <tr *ngFor="let product of productData |  orderBy:order:reverse:true">
      <td class="solvenabold"> {{product.PZN}}</td>
      <td ><span class="solvenabold">{{product.Produkt}}</span>
        <span class="smalltext"><br>{{product.Hersteller}}</span></td>
      <td align="right" class="" >{{product.AVP | number: "0.2-2"}} &euro;
        <span class="smalltext" *ngIf="!product.excludedprice"><br></span>
        <span class="smalltext" *ngIf="product.price-product.AVP > 0 && !product.excludedprice" [ngClass]="getClassOf(product.price-product.AVP)">+</span>
        <span class="smalltext" *ngIf="!product.excludedprice" [ngClass]="getClassOf(product.price-product.AVP)">{{(product.price-product.AVP)/product.AVP*100 | number: "0.1-1"}}%</span></td>
      <td align="right" class="bg-alt" >{{product.rprice | number: "0.2-2"}} &euro;
        <span class="smalltext"><br></span>
        <span class="smalltext" *ngIf="product.price-product.rprice > 0 && !product.excludedprice" [ngClass]="getClassOf(product.price-product.rprice)">+</span>
        <span class="smalltext" *ngIf="!product.excludedprice" [ngClass]="getClassOf(product.price-product.rprice)">{{(product.price-product.rprice)/product.rprice*100 | number: "0.1-1"}}% </span></td>

      <td align="right" class="solvenabold" *ngIf="!editMode">
        <span class="solvenabold" *ngIf="!product.excludedprice"> {{product.price | number: "0.2"}}</span>
        <span *ngIf="product.excludedprice" class="solvenabold"> ---</span> &euro;
      </td>
      <td align="right" *ngIf="editMode">
        <input *ngIf="!product.excludedprice" type="number" pattern="([0-9]{1,5}).([0-9]{2,2})" class="priceinput form-control form-control-small" step="0.01" [(ngModel)]="product.price">
        <span *ngIf="product.excludedprice" class="mx-4">---</span>
      </td>

      <td *ngIf="editMode "><span *ngIf="!product.excludedprice">&euro;</span></td>
      <td *ngIf="editMode"><button type="button" class="btn-primary btn-sm" disabled><i class="fa fa-history"></i></button></td>
      <td align="center" *ngIf="!editMode"><span class="tag" *ngIf="tagprice(product)!==''">{{tagprice(product)}}</span>
      </td>

      <td align="center" *ngIf="editMode && country=='DE'"><input type="checkbox" class="form-check-input"
                                                 (change)="togglepermanentprice(product)"
                                                 [(ngModel)]="product.permanentprice"></td>

      <td align="center" *ngIf="editMode"><input type="checkbox" class="form-check-input"
                                                 (change)="toggleexcludedprice(product)"
                                                 [(ngModel)]="product.excludedprice"></td>
    </tr>
    </tbody>
  </table>
</div>
