<header class="px-4 bg-white container-fluid">
  <nav class="navbar navbar-light justify-content-between"><a href="/" class="navbar-brand"><img
    src="/assets/images/solvena_logo.svg" style="height:50px;margin-right: 20px"></a>
    <span class="dropdown" appDropdown>

      <span class="navbar-text">TRUE PRICE </span>

     <ul class="dropdown-menu appnavigation">
       <li><a>True Price DE</a></li>
       <li><a>True Price AT</a></li>
     </ul>
     </span>
  </nav>
  <br>
</header>
<router-outlet></router-outlet>
<footer class="fixed-bottom mandatoryFooter">Impressum</footer>
