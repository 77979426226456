import { Component } from '@angular/core';
import {GlobalVariables} from './shared/GlobalVariables';
import {GlobalContextService} from './globalContext.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'True Price Demo';
  countryContext = 'DE';

  constructor(
    private readonly globalContext: GlobalContextService,

  ) {

    this.globalContext.countryContextChanged.subscribe(
      (context: string) => {
        this.countryContext = context;
        console.log('received countryContext Change to ' + context + ' Global ' + GlobalVariables.countryContext + ' local ' + this.countryContext)}
    );


    this.countryContext = GlobalVariables.countryContext;
  }
}
