<div class="container-fluid mx-2 my-3 bg-white" *ngIf="showFilter">
  <div class="row">
    <div class="col-1"><i class="fa fa-filter"></i></div>
    <div class="col-3"><select class="form-select form-select-sm " [(ngModel)]="filterCluster" (change)="filterList()">
      <option value="">Cluster</option>
      <option *ngFor="let cluster of clusters" value="{{cluster.value}}">{{cluster.text}}</option>
    </select></div>
    <div class="col-3"><select class="form-select form-select-sm " [(ngModel)]="filterCat" (change)="filterList()">
      <option *ngFor="let cat of filterCategory" value='{{cat}}'>{{cat}}</option>
    </select></div>
    <div class="col-3"><select class="form-select form-select-sm " [(ngModel)]="filterProd" (change)="filterList()">
      <option *ngFor="let comp of filterProducer" value='{{comp.val}}'>{{comp.name}}</option>
    </select></div>

  </div>

</div>

<div class="mx-0">
  <table width="100%">
    <thead>
    <td (click)="setOrder('PZN')">PZN</td>
    <td ><span (click)="setOrder('Produkt')">Produkt</span><span (click)="setOrder('Hersteller')" class="smalltext"><br>Hersteller</span></td>
    <td align="right" (click)="setOrder('units')">Absatz</td>
    <td align="right" class="bg-alt"><span (click)="setOrder('AVP')">AVP</span><span class="smalltext"><br>Abweichung TP</span></td>
    <td align="right" class="bg-alt"><span (click)="setOrder('rprice')">Umfeldpreis</span><span class="smalltext"><br>Abweichung TP</span></td>
    <td align="right"><span (click)="setOrder('price')">Alter Preis</span><span class="smalltext"><br>Abweichung TP</span></td>
    <td align="right"><span (click)="setOrder('finalPrice')">True Price</span></td>
    <td align="center"></td>
    </thead>
    <tbody>
    <tr *ngFor="let product of productList |  orderBy:order:reverse:true">
      <td>{{product.PZN}}</td>
      <td>{{product.Produkt}}<span class="smalltext"><br>{{product.Hersteller}}</span></td>
      <td align="right">{{product.units}}</td>
      <td align="right" class="bg-alt">
        {{product.AVP | number: "0.2-2"}} &euro;<br>
        <span class="smalltext" *ngIf="product.finalPrice-product.AVP > 0 && !product.excludedprice" [ngClass]="getClassOf(product.finalPrice-product.AVP)">+</span>
        <span class="smalltext" [ngClass]="getClassOf(product.finalPrice-product.AVP)">{{(product.finalPrice-product.AVP)/product.AVP*100 | number:'0.1-1'}}%</span>
      </td>
      <td align="right" class="bg-alt">
        {{product.rprice | number: "0.2-2"}} &euro;<br>
        <span class="smalltext" *ngIf="product.finalPrice-product.rprice > 0 && !product.excludedprice" [ngClass]="getClassOf(product.finalPrice-product.rprice)">+</span>
        <span class="smalltext" [ngClass]="getClassOf(product.finalPrice-product.rprice)">{{(product.finalPrice-product.rprice)/product.rprice*100 | number:'0.1-1'}}%</span>
      </td>
      <td align="right">
        {{product.price | number: "0.2-2"}} &euro;<br>
        <span class="smalltext" *ngIf="product.finalPrice-product.price > 0 && !product.excludedprice" [ngClass]="getClassOf(product.finalPrice-product.price)">+</span>
        <span class="smalltext" [ngClass]="getClassOf(product.finalPrice-product.price)">{{(product.finalPrice-product.price)/product.price*100 | number:'0.1-1'}}%</span>
      </td>
      <td align="right" class="solvenabold bg-alt">
        <span class="solvenabold" *ngIf="!product.excludedprice"> {{product.finalPrice | number: "0.2-2"}} &euro;<br></span>
        <span *ngIf="!product.excludedprice" class="smalltext">{{product.truePrice | number: "0.2-2"}} &euro;</span>
        <span *ngIf="product.excludedprice" class="solvenabold"> --- </span></td>
      <td align="center"><span class="tag" *ngIf="tagprice(product)!==''">{{tagprice(product)}}</span></td>
    </tr>
    </tbody>
  </table>
</div>
