import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-context-selector',
  templateUrl: './selectcontext.component.html'
})
export class SelectContextComponent  {

  selectedContext = 0;

  pharmacyName = "Alle (Admin-Ansicht)";
  pharmacyStreet = "";
  pharmacyZipcode = "";
  pharmacyCity = "";


  @Output() context: EventEmitter<number> = new EventEmitter<number>();

  constructor() {
  }
}
