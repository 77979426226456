import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {GlobalVariables} from '../../shared/GlobalVariables';

@Component({
  selector: 'app-adminheader',
  templateUrl: './adminheader.component.html',
  styleUrls: ['./adminheader.component.css']
})
export class AdminheaderComponent implements OnInit {
  @Input() active = 'pharmacies';

  collapsed = true;
  username = 'Demo Admin';




  constructor(protected router: Router) {
  }

  ngOnInit(): void {

  }

  gotoImprint(): void {
    if (GlobalVariables.countryContext === 'DE') {
      window.location.href = 'https://solvena.de/impressum/';
    } else {
      window.location.href = 'https://solvena.at/impressum/';
    }

  }

  gotoDataProtection(): void {
    if (GlobalVariables.countryContext === 'DE') {
      window.location.href = 'https://solvena.de/datenschutzerklaerung/';
    } else {
      window.location.href = 'https://solvena.at/datenschutzerklaerung/';
    }
  }

  navigateTo(tag: string): void {
    this.router.navigate([tag]);
  }

  logout(): void {
    this.router.navigateByUrl('/');
  }
}
