<app-adminheader active="pharmacy"></app-adminheader>
<div class="container-fluid mx-2 my-3">
  <div class="row">
    <div><h3>Hotfix Preisliste - generiert am 22. Januar 2022</h3></div>
  </div>
  <div class="row kpi-header">
    <div class="col-3">Musterapotheke Weinheim<br>Karpfenweiher 12<br>67263 Musterhausen</div>
    <div class="col-1">
      <button type="button" class="btn-primary" (click)="toggleFilter()">Filter</button>
    </div>
    <div class="col-2"><span class="solvenabold">Anzahl PZN</span><br>
      <h3>{{pznCount}}</h3></div>
    <div class="col-2"><span class="solvenabold">Absatz</span><br>
      <h3>{{packages}}</h3></div>
    <div class="col-2"><span class="solvenabold">Umsatz Alt</span><br>
      <h3>{{turnoverOld | number: '0.2'}} EUR</h3></div>
    <div class="col-2"><span class="solvenabold">Umsatz Neu</span><br>
      <h3>{{turnoverNew | number: '0.2'}} EUR</h3></div>
  </div>

</div>
<div class="container-fluid mx-2 my-3 bg-white" *ngIf="showFilter">
  <div class="row">
    <div class="col-1 solvenabold">FILTER</div>
    <div class="col-2"><select class="form-select form-select-sm " (change)="filterList()" [(ngModel)]="filterClass">
      <option *ngFor="let pc of filterProductClass" value='{{pc.val}}'>{{pc.name}}</option>
    </select></div>
    <div class="col-3"><select class="form-select form-select-sm " [(ngModel)]="filterCat" (change)="filterList()">
      <option *ngFor="let cat of filterCategory" value='{{cat}}'>{{cat}}</option>
    </select></div>
    <div class="col-3"><select class="form-select form-select-sm " [(ngModel)]="filterProd" (change)="filterList()">
      <option *ngFor="let comp of filterProducer" value='{{comp.val}}'>{{comp.name}}</option>
    </select></div>
    <div class="col-3">Suche</div>
  </div>

</div>
<div class="container-fluid mx-2 my-3 bg-white">
  <div class="row">
    <div class="col-2" [ngClass]="selectedView == 1 ? 'solvenabold' : 'black-50'" (click)="selectList(1)">Auffällige Werte</div>
    <div class="col-2" [ngClass]="selectedView == 2 ? 'solvenabold' : 'black-50'" (click)="selectList(2)">Topseller</div>
    <div class="col-2" [ngClass]="selectedView == 3 ? 'solvenabold' : 'black-50'" (click)="selectList(3)">Gesamtliste</div>
  </div>
</div>
<div class="mx-4">
  <table width="100%">
    <thead>
    <td>PZN</td>
    <td>Produkt</td>
    <td>Hersteller</td>
    <td align="right">True Price</td>
    <td align="right"></td>
    <td align="right" class="bg-alt">AVP</td>
    <td align="right">Alter Preis</td>
    <td align="right">Finaler Preis</td>
    <td align="center">Dauer-<br>preis</td>
    <td align="center">Aus-<br>schluss</td>
    </thead>
    <tbody>
    <tr *ngFor="let product of productData" >
      <td>{{product.PZN}}</td>
      <td>{{product.Produkt}}</td>
      <td>{{product.Hersteller}}</td>
      <td align="right" class="solvenabold">{{product.price | number: '0.2'}} EUR</td>
      <td><button class="btn-primary btn-sm">Info</button></td>
      <td align="right" class="bg-alt">{{product.AVP | number: "0.2"}} EUR</td>
      <td align="right">{{product.pricebefore | number: "0.2"}} EUR</td>
      <td align="right" class="solvenabold"><input [(ngModel)]="product.finalPrice" type="text"
                                                   style="text-align:right; width: 70px;"
                                                   (change)="updateList()"> EUR</td>
      <td align="center"><input type="checkbox" class="form-check-input"></td>
      <td align="center"><input type="checkbox" class="form-check-input"></td>
    </tr>
    </tbody>
  </table>
</div>
<footer class="fixed-bottom p-2 container-fluid solvena-bottom-toolbar" align="right ">
  <div class="row">
    <div class="col-6  bg-white" align="left">
      <button type="button" class="btn-secondary" (click)="backClick()">Zurück</button>
    </div>
    <div class="col-6  bg-white" align="right">
      <button class="btn-primary mx-4" type="button" (click)="saveClick()">Speichern</button>
      <button class="btn-primary" type="button" (click)="approveClick()">Hotfix Preise freigeben</button>
    </div>
  </div>
</footer>
<div [ngStyle]="{'display':showModal}" class="modal" role="dialog" data-bs-backdrop="static">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">{{modalTitle}}</h3>
      </div>
      <div class="modal-body">
        <p>{{modalText}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="closeModal()">Schließen</button>
      </div>
    </div>
  </div>
</div>
