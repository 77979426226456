<app-pharmacayhomepage active="pharmacies"></app-pharmacayhomepage>
<div class="container-fluid mx-2 my-3">
  <div class="row">
    <div><h3>Apotheken Dashboard</h3></div>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="card">
        <div class="card-header">Stammdaten</div>
        <div class="card-body" style="color: #555">
          Musterapotheke Weinheim<br>
          Karpfenweiher 12<br>
          67263 Musterhausen<br><br>
          Herr Max Headroom<br>
          mh@musterapotheke-weinheim.da<br>
          04837-27282347
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="card">
        <div class="card-header">Verknüpfte Apotheken</div>
        <div class="card-body" style="color: #555">
          Musterfiliale Weinheim<br>
          Am Krankenaus 12<br>
          67263 Musterhausen<br>
          <span style="color: darkseagreen">Gemeinsame Berechnungsbasis</span>
        </div>
      </div>
    </div>
    <div class="col-6 mt-3">
      <div class="card">
        <div class="card-header">Aktuelle Preisliste Freiwahl</div>
        <div class="card-body" style="color: #555">

          <div class="row mb-4">
            <div class="col-5">Simuliert am</div>
            <div class="col-7">15.02.2022</div>
            <div class="col-5">Freigegeben am</div>
            <div class="col-7">17.02.2022</div>
            <div class="col-5">Freigegeben durch</div>
            <div class="col-7">Herr Max Headroom</div>
            <div class="col-5">Eingespielt am</div>
            <div class="col-7">18.02.2022</div>
          </div>

          <button type="button" class="btn-primary" (click)="openPricelist()">Online öffnen</button>
          <button type="button" class="btn-primary mx-2">PDF laden</button>
          <button type="button" class="btn-primary">CSV laden</button>
          <button type="button" class="btn-primary" (click)="startSimulation()">Folgesimulation starten</button>
        </div>
      </div>


    </div>
    <div class="col-6 mt-3">
      <div class="card">
        <div class="card-header">Aktuelle Preisliste Sichtwahl</div>
        <div class="card-body" style="color: #555">

          <div class="row mb-4">
            <div class="col-5">Simuliert am</div>
            <div class="col-7">15.02.2022</div>
            <div class="col-5">Freigegeben am</div>
            <div class="col-7">17.02.2022</div>
            <div class="col-5">Freigegeben durch</div>
            <div class="col-7">Herr Max Headroom</div>
            <div class="col-5">Eingespielt am</div>
            <div class="col-7">18.02.2022</div>
          </div>

          <button type="button" class="btn-primary" (click)="openPricelist()">Online öffnen</button>
          <button type="button" class="btn-primary mx-2">PDF laden</button>
          <button type="button" class="btn-primary">CSV laden</button>
          <button type="button" class="btn-primary" (click)="startSimulation()">Folgesimulation starten</button>
        </div>
      </div>
    </div>
  </div>
</div>
<footer class="fixed-bottom p-2 container-fluid solvena-bottom-toolbar" align="right ">
  <div class="row">

    <div class="col-6 bg-white" align="left">
      <button type="button" class="btn-secondary" (click)="backClick()">Zurück</button>
    </div>
    <div class="col-6  bg-white" align="right">
      <button type="button" class="btn-primary" >Gesamtpreisliste PDF</button>
    </div>
  </div>
</footer>
