<form (ngSubmit)="onSubmit(f)" #f="ngForm">
  <div class="container-fluid footerSpace">
    <div class="row">
      <!--<div class="col-3 d-none d-lg-block bg-white">
        <div class="py-2 mx-4 mt-3 smalltext">{{pharmacy.name}}<br>{{pharmacy.street}}
          <br>{{pharmacy.zipcode}} {{pharmacy.city}}<br></div>
        <div class="py-4">
          <ul class="kcprogress" *ngFor="let progressStep of progressNavigationData">
            <li
              [ngClass]="{
            'kcprogressActive': this.step==progressStep.step,
            'kcprogressActivated': this.step > progressStep.step}">{{progressStep.label}}</li>
          </ul>
        </div>
      </div>-->


      <div class="col-12 ">
        <div class="mx-3 mt-3"><h3>True Price Preissimulation {{step}}</h3></div>
        <div class="container" *ngIf="step<5">
          <div class="accordion" id="accordionExample">
            <div class="steps">
              <progress id="progress" #progressElement max=100 ng-non-bindable></progress>
              <div class="step-item">
                <button type="button" class="step-button text-center collapsed step-button-current"
                        (click)="setProcessStep(1)"
                        [ngClass]="{
            'step-button-current': this.step==1,
            'done': this.step > 1,
            'collapsed': this.step != 1}">
                  1
                </button>
                <div class="step-title">
                  Aktueller Stand
                </div>
              </div>
              <div class="step-item">
                <button class="step-button text-center collapsed" type="button" (click)="setProcessStep(2)"
                        [ngClass]="{
            'step-button-current': this.step==2,
            'done': this.step > 2,
            'collapsed': this.step != 2}">
                  2
                </button>
                <div class="step-title">
                  Produktgruppen
                </div>
              </div>
              <div class="step-item">
                <button class="step-button text-center collapsed" type="button" (click)="setProcessStep(3)"
                        [ngClass]="{
            'step-button-current': this.step==3,
            'done': this.step > 3,
            'collapsed': this.step != 3}">
                  3
                </button>
                <div class="step-title">
                  Produktliste
                </div>
              </div>
              <div class="step-item">
                <button class="step-button text-center collapsed" type="button" (click)="setProcessStep(4)"
                        [ngClass]="{
            'step-button-current': this.step==4,
            'done': this.step > 4,
            'collapsed': this.step != 4}">
                  4
                </button>
                <div class="step-title">
                  Rundungen, Grenzwerte
                </div>
              </div>
              <div class="step-item">
                <button class="step-button text-center collapsed" type="button" disabled [ngClass]="{
            'step-button-current': this.step==5,
            'done': this.step > 5,
            'collapsed': this.step != 5}">
                  5
                </button>
                <div class="step-title">
                  Bestätigung
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="row kpi-header mb-2 mx-2">
          <div class="col-4">
            <div class="kpi"><span class="solvenabold">Preisbasis (Datenstand 01/22)</span>
              <div class="row mt-0" *ngIf="country==='DE'">
                <div class="col-6"><input type="radio" class="form-check-input" name="umfeld" id="ravp" value="ravp"
                                          [(ngModel)]="umfeld" (change)="umfeldChanged()">
                  <label class="form-check-label mx-2" for="ravp">
                    rAVP
                  </label></div>
                <div class="col-6"><input type="radio" class="form-check-input" name="umfeld" id="avp" value="avp"
                                          [(ngModel)]="umfeld" (change)="umfeldChanged()">
                  <label class="form-check-label mx-2" for="avp">
                    AVP
                  </label></div>
              </div>
            </div>
          </div>
          <div class="col-2 px-0">
            <div class="kpi"><span class="solvenabold">Anzahl PZN</span><br>
              <h3 class="solvenagreen">{{pznCount | number: '1.0-0'}}</h3></div>
          </div>
          <div class="col-2">
            <div class="kpi"><span class="solvenabold">Absatz</span><br>
              <h3 class="solvenagreen">{{packages | number: '1.0-0'}}</h3></div>
          </div>
          <div class="col-2 px-0">
            <div class="kpi"><span class="solvenabold">Umsatz Alt</span><br>
              <h3 class="solvenagreen">{{turnoverOld | number: '0.0-0'}} &euro;</h3></div>
          </div>
          <div class="col-2">
            <div class="kpi"><span class="solvenabold">Umsatz Neu</span>
              <h3 class="solvenagreen">{{turnoverNew | number: '0.0-0'}} &euro;</h3>
              <span class="kpidiff"><span
                *ngIf="turnoverNew-turnoverOld>0">+</span>{{turnoverNew - turnoverOld |number: '0.0-0'}} &euro; |
          <span
            *ngIf="turnoverNew-turnoverOld>0">+</span>{{(turnoverNew - turnoverOld) / turnoverOld * 100 | number:'0.1-1'}}
                %</span>
            </div>
          </div>
        </div>
        <div class="mx-3 mt-4"><h2>{{headline}}</h2></div>


        <!-- Schritt 1 - Grundeinstellungen eingeben -->
        <!-- <div class="form-floating mb-1" *ngIf="step===1" ngModelGroup="settings">
           <div class="mx-3 p-0"><h3>Schritt 1 - Grundeinstellungen</h3></div>
           <div class="bg-white mx-3 py-2 px-1">Datenstand <br>
             Nehmen Sie hier die Grundeinstellungen für die True Price Berechnung vor.
           </div>

           <div class="bg-white mx-3 my-3 px-1"><span class="solvenabold">Preisbasis</span>

           </div>

           <div class="bg-white mx-3 my-3 px-1"><span class="solvenabold">Warengruppe</span>
             <div class="row mt-2">
               <div class="col-4"><input type="radio" class="form-check-input" name="warengruppe" id="freiwahl"
                                         value="freiwahl" [(ngModel)]="warengruppe" (change)="warengruppeChanged()">
                 <label class="form-check-label mx-2" for="freiwahl">
                   Freiwahl
                 </label></div>
               <div class="col-4"><input type="radio" class="form-check-input" name="warengruppe" id="sichtwahl"
                                         value="sichtwahl" [(ngModel)]="warengruppe" (change)="warengruppeChanged()">
                 <label class="form-check-label mx-2" for="sichtwahl">
                   OTC
                 </label></div>
               <div class="col-4"><input type="radio" class="form-check-input" name="warengruppe" id="Hilfsmittel"
                                         value="beides" [(ngModel)]="warengruppe" (change)="warengruppeChanged()">
                 <label class="form-check-label mx-2" for="Hilfsmittel">
                   Hilfsmittel
                 </label></div>
             </div>
           </div>

         </div> -->

        <div class="form-floating mb-1" *ngIf="step===1" ngModelGroup="settings">
          <div class="mx-3 p-0"><h3>Schritt 1 - Aktueller Stand</h3></div>
          <div class="bg-white mx-3 py-2 px-1 shadow-sm">
            <table cellpadding="4">
              <thead>
              <tr>
                <th></th>
                <th></th>
                <th>Umfeldpreis rAVP</th>
                <th>Listenpreis AVP</th>
              </tr>
              <tr>
                <th></th>
                <th></th>
                <td align="right" class="solvenabold">{{productStatistics.ravpCurrent | number: '1.2-2'}} &euro;</td>
                <td align="right" class="solvenabold">{{productStatistics.avpCurrent | number: '1.2-2'}} &euro;</td>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>Apothekenpreis NEU (wird in den Segmenten verändert)</td>
                <td align="right" class="solvenabold solvenagreen">{{truepriceStat | number: '1.2-2'}} &euro;</td>
                <td align="right"
                    class="solvenabold solvenagreen">{{ (truepriceStat - productStatistics.ravpCurrent) / truepriceStat * 100 | number: '1.2-2'}}
                  %
                </td>
                <td align="right"
                    class="solvenabold solvenagreen">{{ (truepriceStat - productStatistics.avpCurrent) / truepriceStat * 100 | number: '1.2-2'}}
                  %
                </td>
              </tr>
              <tr>
                <td>Apothekenpreis alt rAVP</td>
                <td align="right" class="solvenabold">{{productStatistics.ravpOld | number: '1.2-2'}} &euro;</td>
                <td
                  align="right">{{ (productStatistics.ravpOld - productStatistics.ravpCurrent) / productStatistics.ravpOld * 100 | number: '1.2-2'}}
                  %
                </td>
                <td
                  align="right">{{ (productStatistics.ravpOld - productStatistics.avpCurrent) / productStatistics.ravpOld * 100 | number: '1.2-2'}}
                  %
                </td>
              </tr>
              <tr>
                <td>Apothekenpreis alt AVP</td>
                <td align="right" class="solvenabold">{{productStatistics.avpOld | number: '1.2-2'}} &euro;</td>
                <td
                  align="right">{{ (productStatistics.avpOld - productStatistics.ravpCurrent) / productStatistics.avpOld * 100 | number: '1.2-2'}}
                  %
                </td>
                <td
                  align="right">{{ (productStatistics.avpOld - productStatistics.avpCurrent) / productStatistics.avpOld * 100 | number: '1.2-2'}}
                  %
                </td>
              </tr>
              <tr>
                <td>Niedrigstpreise rAVP (unteres Preisdrittel)</td>
                <td align="right" class="solvenabold">{{productStatistics.lowestRavp | number: '1.2-2'}} &euro;</td>
                <td
                  align="right">{{ (productStatistics.lowestRavp - productStatistics.ravpCurrent) / productStatistics.lowestRavp * 100 | number: '1.2-2'}}
                  %
                </td>
                <td
                  align="right">{{ (productStatistics.lowestRavp - productStatistics.avpCurrent) / productStatistics.lowestRavp * 100 | number: '1.2-2'}}
                  %
                </td>
              </tr>
              <tr>
                <td>Höchstpreise rAVP (oberes Preisdrittel)</td>
                <td align="right" class="solvenabold">{{productStatistics.highestRavp | number: '1.2-2'}} &euro;</td>
                <td
                  align="right">{{ (productStatistics.highestRavp - productStatistics.ravpCurrent) / productStatistics.highestRavp * 100 | number: '1.2-2'}}
                  %
                </td>
                <td
                  align="right">{{ (productStatistics.highestRavp - productStatistics.avpCurrent) / productStatistics.highestRavp * 100 | number: '1.2-2'}}
                  %
                </td>
              </tr>
              </tbody>
            </table>

          </div>


        </div>

        <!-- Schritt 2 - Produktgruppen -->
        <div class="form-floating mb-5" *ngIf="step===2 && country==='DE'" ngModelGroup="groups">
          <div class="mx-3 p-0 pb-2"><h3 class="mb-1 pb-0">Schritt 2 - Produktgruppen </h3>
            {{warengruppe}} basierend auf {{umfeld}}
          </div>


          <div class="mx-3">
            <div class="card mb-3" *ngFor="let cluster of pecluster">
              <!-- Deutschland --------------------------------------------------------------------------------------->
              <div *ngIf="cluster.id<3 && country==='DE'">
                <div class="card-header"> {{cluster.name}}</div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-2 text-center">Anzahl PZN<br> <span class="solvenagreen">{{ cluster.pzn }}</span>
                    </div>
                    <div class="col-2 text-center">
                      Umsatzanteil<br><span class="solvenagreen">{{ cluster.percentage | number: '0.1-1'}}%</span>
                    </div>
                    <div class="col-2 text-center">Umsatz alt<br><span
                      class="solvenagreen"> {{cluster.turnoverold | number: '0.0-0'}} &euro;</span></div>
                    <div class="col-2 text-center">Umsatz neu<br><span
                      class="solvenagreen">{{cluster.turnovernew | number: '0.0-0'}} &euro;</span></div>
                    <div class="col-4">

                      <input type="button"
                             (click)="showClusterPriceList(cluster.id)"
                             class="btn-primary btn-sm"
                             value="Produkte">
                      <button type="button" *ngIf="country=='DE' && !cluster.display" class="btn-primary btn-sm mx-3"
                              (click)="toggleInfo(cluster.id)">Mehr Info
                      </button>
                      <button type="button" *ngIf="country=='DE' && cluster.display" class="btn-primary btn-sm mx-3"
                              (click)="toggleInfo(cluster.id)">Weniger Info
                      </button>
                    </div>
                  </div>

                  <div class="row mx-2">
                    <div class="col-8" style="z-index:10">
                      <div class="solvenaslider">
                        <!--<input style=" z-index:100" class="form-range position-relative" type="range" min="-20"
                               max="20" step="0.1"
                               value="{{cluster.change}}"
                               (input)="valueChanged($event,cluster.id)"> -->
                        <ngx-slider [(value)]="cluster.change" [manualRefresh]="cluster.manualRefresh"
                                    [options]="cluster.slideroptions"
                                    (userChangeEnd)="sliderValueChange($event,cluster)"></ngx-slider>
                        <!--<ngx-slider *ngIf="umfeld=='avp'" [(value)]="cluster.change" [(ngModel)]="cluster.manualRefresh"
                                    [options]="cluster.slideroptionsAvp"
                                    (userChange)="sliderValueChange($event,cluster.id)"></ngx-slider>-->

                      </div>
                    </div>
                    <div class="col-2 pt-4 solvenagreen solvenabold">{{cluster.change}} %</div>
                    <div class="col-2">
                      <div class="row">
                        <div class="col-3">
                          <div class="dot bluedot"></div>
                        </div>
                        <div class="col-9 smalltext">AVP</div>
                        <div class="col-3">
                          <div class="dot reddot"></div>
                        </div>
                        <div class="col-9 smalltext">Umfeldpreis</div>
                        <div class="col-3">
                          <div class="dot browndot"></div>
                        </div>
                        <div class="col-9 smalltext">Alter Preis</div>
                      </div>
                    </div>
                  </div>

                  <div class="mx-3 my-3 row" *ngIf="cluster.display">

                    <div class="col-8">
                      <table width="100%" class="statisticstable">
                        <thead>
                        <tr>
                          <th></th>
                          <th>Trueprice<br> %-Abweichung</th>
                          <th>Alter Apothekenpreis<br> %-Abweichung</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td class="statfirstcol" align="right">Alter Apothekenpreis</td>
                          <td
                            align="center">{{(cluster.averagenew - cluster.oldprice) / cluster.averagenew * 100 |number:'1.2-2'}}
                            %
                          </td>
                          <td align="center">{{0 |number:'1.2-2'}} %</td>
                        </tr>
                        <tr>
                          <td class="statfirstcol" align="right">AVP</td>
                          <td
                            align="center">{{(cluster.averagenew - cluster.averageavp) / cluster.averagenew * 100 |number:'1.2-2'}}
                            %
                          </td>
                          <td
                            align="center">{{(cluster.oldprice - cluster.averageavp) / cluster.oldprice * 100 |number:'1.2-2'}}
                            %
                          </td>
                        </tr>
                        <tr>
                          <td class="statfirstcol" align="right">Umfeldpreis</td>
                          <td
                            align="center">{{(cluster.averagenew - cluster.averageravp) / cluster.averagenew * 100 |number:'1.2-2'}}
                            %
                          </td>
                          <td
                            align="center">{{(cluster.oldprice - cluster.averageravp) / cluster.oldprice * 100 |number:'1.2-2'}}
                            %
                          </td>
                        </tr>
                        <tr>
                          <td class="statfirstcol" align="right">Niedrigster Umfeldpreis
                          </td>
                          <td
                            align="center">{{(cluster.averagenew - cluster.lowestravp) / cluster.averagenew * 100 |number:'1.2-2'}}
                            %
                          </td>
                          <td
                            align="center">{{(cluster.oldprice - cluster.lowestravp) / cluster.oldprice * 100 |number:'1.2-2'}}
                            %
                          </td>
                        </tr>
                        <tr>
                          <td class="statfirstcol" align="right">Höchster Umfeldpreis</td>
                          <td
                            align="center">{{(cluster.averagenew - cluster.highestravp) / cluster.averagenew * 100 |number:'1.2-2'}}
                            %
                          </td>
                          <td
                            align="center">{{(cluster.oldprice - cluster.highestravp) / cluster.oldprice * 100 |number:'1.2-2'}}
                            %
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Österreich --------------------------------------------------------------------------------------->
        <div class="form-floating mb-1" *ngIf="step===2 && country==='AT'" ngModelGroup="groups">
          <div class="row">
            <div class="col-9">
              <div class="mx-3 p-0 pb-2"><h3 class="mb-1 pb-0">Schritt 2 - Produktgruppen </h3>
                {{warengruppe}} basierend auf AVP
              </div>
            </div>
            <div class="col-3">
              <div class="row">
                <div class="col-3">
                  <div class="dot bluedot"></div>
                </div>
                <div class="col-9 smalltext"><span *ngIf="country==='AT'">Listen-</span>AVP</div>
                <div class="col-3">
                  <div class="dot reddot"></div>
                </div>
                <div class="col-9 smalltext">Umfeldpreis</div>
                <div class="col-3">
                  <div class="dot browndot"></div>
                </div>
                <div class="col-9 smalltext">Alter Preis</div>
              </div>
            </div>

          </div>
          <div class="container-fluid mx-0 my-3 " *ngFor="let cluster of pecluster">
            <div class="row bg-white mx-0 my-2 topborder shadow-sm">
              <div class="col-5 solvenabold">{{cluster.name}}</div>
              <div class="col-7">
                <div class="row">
                  <div class="col-1 smalltext">PZN<br><span class="solvenagreen">{{ cluster.pzn }}</span></div>
                  <div class="col-1 smalltext">
                    Anteil<br><span class="solvenagreen"> {{ cluster.percentage | number: '0.1-1'}}%</span>
                  </div>

                  <div class="col-3 smalltext text-end">Umsatz alt<br> <span
                    class="solvenagreen">{{cluster.turnoverold | number: '0.0-0'}} &euro;</span></div>
                  <div class="col-3 smalltext text-end">Umsatz neu<br> <span
                    class="solvenagreen">{{cluster.turnovernew | number: '0.0-0'}} &euro;</span>
                  </div>
                  <div class="col-4  text-end">
                    <input type="button"
                           (click)="showClusterPriceList(cluster.id)"
                           class="btn-primary btn-sm"
                           value="Produkte">
                  </div>
                </div>
              </div>
              <div class="col-9 solvenaslider">
                <ngx-slider *ngIf="cluster.hide==false" [(value)]="cluster.change"
                            [manualRefresh]="cluster.manualRefresh"
                            [options]="cluster.slideroptions"
                            (userChangeEnd)="sliderValueChange($event,cluster)"></ngx-slider>
                <!-- <ngx-slider *ngIf="umfeld=='avp'" [(value)]="peclusterat[3].change"
                             [(ngModel)]="peclusterat[3].manualRefresh"
                             [options]="peclusterat[3].slideroptionsAvp"
                             (userChangeEnd)="sliderValueChange($event,peclusterat[3].id)"></ngx-slider> -->
                <!--<input class="form-range" type="range" min="-20" max="20" step="0.1"
                       value="{{aclusterchange}}"
                       (input)="valueChanged($event,-1)">-->
              </div>
              <div class="col-2 px-0 mt-4 solvenabold solvenagreen">{{cluster.change}} %<br><span
                class="smalltext solvenagreen" style="cursor:pointer" (click)="cluster.change=0">Reset</span></div>
              <div class="col-1 d-flex align-items-end pb-3 text-end">
                <button *ngIf="!cluster.showSubcluster" type="button" class="btn-primary btn-sm"
                        (click)="toggleSubcluster(cluster)">+
                </button>
                <button *ngIf="cluster.showSubcluster" type="button" class="btn-primary btn-sm"
                        (click)="toggleSubcluster(cluster)">-
                </button>
              </div>
            </div>
            <div class="row" *ngIf="cluster.showSubcluster">
              <div class="col-4" *ngFor="let subcluster of cluster.subclusters">
                <div class="mx-0 my-2 bg-white py-2 topborder-sm shadow-sm">
                  <div class="row mx-2">
                    <div class="col-7 solvenabold"> {{subcluster.name}}</div>
                    <div class="col-5"><input type="button"
                                              (click)="showClusterPriceList(subcluster.id + subcluster.label)"
                                              class="btn-primary btn-sm"
                                              value="Produkte"></div>

                    <div class="col-9 solvenaslider">
                      <!--<input class="form-range" type="range" min="-20" max="20" step="0.1"
                             value="{{cluster.change}}"
                             (input)="valueChanged($event,cluster.id)">
                             -->

                      <ngx-slider [(value)]="subcluster.change"
                                  [manualRefresh]="subcluster.manualRefresh"
                                  [options]="subcluster.slideroptions"
                                  (userChangeEnd)="sliderValueChange($event,subcluster)"
                                  name="subclusterslider"></ngx-slider>
                      <!--<ngx-slider *ngIf="warengruppe=='ravp'" [(value)]="subcluster.change"
                                  [(ngModel)]="subcluster.manualRefresh"
                                  [options]="subcluster.slideroptions"
                                  (userChange)="sliderValueChange($event,subcluster.id)"></ngx-slider>-->
                    </div>
                    <div class="col-3 px-0 mt-4 solvenabold solvenagreen">{{subcluster.change}} %<br><span
                      class="smalltext solvenagreen" style="cursor:pointer" (click)="subcluster.change=0">Reset</span>
                    </div>


                    <div class="col-2 smalltext">PZN<br><span class="solvenagreen">{{ subcluster.pzn }}</span></div>
                    <div class="col-2 smalltext">
                      Anteil<br><span class="solvenagreen"> {{ subcluster.percentage | number: '0.1-1'}}%</span>
                    </div>

                    <div class="col-4 smalltext text-end">alt<br> <span
                      class="solvenagreen">{{subcluster.turnoverold | number: '0.0-0'}} &euro;</span></div>
                    <div class="col-4 smalltext text-end">neu<br> <span
                      class="solvenagreen">{{subcluster.turnovernew | number: '0.0-0'}} &euro;</span>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
          <div><br><br><br></div>
        </div>


        <!-- Schritt 3 - Produktliste -->
        <div class="form-floating mb-1" *ngIf="step===3" ngModelGroup="pricelist">
          <div class="mx-3 p-0">
            <div class="row">
              <div class="col-9"><h3>Schritt 3 - Produktliste</h3>
                {{warengruppe}} basierend auf {{umfeld}}</div>
              <!--<div class="col-4 form-check form-switch justify-content-end d-inline-flex">
                <input class="form-check-input" type="checkbox" id="filterSwitch" [(ngModel)]="showFilter">
                <label class="form-check-label" for="filterSwitch">Filter</label>
              </div>-->

              <div class="col-3">
                <div class="row mt-4">
                  <div class="col-12 smallertext" *ngIf="country=='DE'"><span class="tag">D</span> Dauerpreis</div>
                  <div class="col-12 smallertext"><span class="tag">M</span> Manuell gesetzter Preis<br></div>
                  <div class="col-12 smallertext"><span class="tag">N</span> Nicht berücksichtigt<br></div>
                </div>
              </div>
            </div>
            <app-truepricetable [showFinalPrice]="true" [showFilter]="showFilter"
                                [productData]="productData" [filterCluster]="filterCluster"></app-truepricetable>
          </div>
        </div>

        <!-- Schritt 4 - Rundung / Grenzwerte -->
        <div class="form-floating mb-1" *ngIf="step===4" ngModelGroup="rounding">
          <div class="row">
            <div class="col-8">
              <div class="mx-3 p-0"><h3>Schritt 4 - Feintuning</h3></div>
              <div class="container-fluid m-0">
                <div class="row">
                  <div class="col-12">
                    <h3>Rundung</h3>
                    <p class="smalltext">Die Preise können noch leicht verbessert werden, indem sie nach der True Price
                      Kalkulation
                      aufgerundet werden. Folgende Rundungskonzepte stehen zur Verfügung
                    </p>
                  </div>
                  <div class="col-6 bg-white smalltext">
                    <input type="radio" class="form-check-input" name="rundung" id="keine" value="keine"
                           [(ngModel)]="rundung" (change)="rundungChanged()">
                    <label class="form-check-label mx-2" for="keine">
                      Keine Rundungen verwenden
                    </label>
                  </div>
                  <div class="col-6  bg-white smalltext">
                    <input type="radio" class="form-check-input" name="rundung" id="rnd5000" value="rnd5000"
                           [(ngModel)]="rundung" (change)="rundungChanged()">
                    <label class="form-check-label mx-2" for="rnd5000">
                      Rundungen auf .50 / .00
                    </label>
                  </div>
                  <div class="col-6  bg-white smalltext">
                    <input type="radio" class="form-check-input" name="rundung" id="rnd4999" value="rnd4999"
                           [(ngModel)]="rundung" (change)="rundungChanged()">
                    <label class="form-check-label mx-2" for="rnd4999">
                      Rundungen auf .49 / .99
                    </label>
                  </div>
                  <div class="col-6  bg-white smalltext">
                    <input type="radio" class="form-check-input" name="rundung" id="rndlast" value="rndlast"
                           [(ngModel)]="rundung" (change)="rundungChanged()">
                    <label class="form-check-label mx-2" for="rndlast">
                      Rundungen auf letzte Stelle </label>
                    <select class="form-select-sm " [(ngModel)]="lastDigit" name="lastDigit"
                            (change)="rundungChanged()">
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="3">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                    </select>

                  </div>
                  <div class="col-6  bg-white smalltext">
                    <input type="radio" class="form-check-input" name="rundung" id="rndarznei" value="rndarznei"
                           [(ngModel)]="rundung" (change)="rundungChanged()">
                    <label class="form-check-label mx-2" for="rndarznei">
                      Rundungen <span *ngIf="country=='AT'">nach Arzneitaxe</span> auf ..10 / ..05
                    </label>
                  </div>
                  <div class="col-6 bg-white smalltext">
                    <input type="radio" class="form-check-input" name="rundung" id="rndungerade" value="rndungerade"
                           [(ngModel)]="rundung" (change)="rundungChanged()">
                    <label class="form-check-label mx-2" for="rndungerade">
                      Rundungen auf .19/.39/.59/.79/.99
                    </label>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12">
                    <h3>Grenzwerte</h3>
                    <p class="smalltext">Hier können Sie eine Preisgrenze definieren. Hat ein Produkt einen TruePrice
                      oberhalb dieser
                      Grenze,
                      wird er auf den AVP des Herstellers begrenzt.
                    </p>
                  </div>

                  <div class="col-6 bg-white smalltext" *ngIf="country === 'AT'">
                    <div class="smalltext">Maximal rAVP/AVP plus <select class="form-select-sm" name="grenzwert"
                                                                         [(ngModel)]="grenzwertplus"
                                                                         name="grenzwertplus"
                                                                         (change)="updateGrenzwerte()">
                      <option value="0">0 €</option>
                      <option value="5">5 €</option>
                      <option value="10">10 €</option>
                      <option value="15">15 €</option>
                      <option value="20">20 €</option>
                      <option value="25">25 €</option>
                      <option value="30">30 €</option>
                      <option value="35">35 €</option>
                      <option value="40">40 €</option>
                      <option value="45">45 €</option>
                      <option value="50">50 €</option>
                      <option value="55">55 €</option>
                      <option value="60">60 €</option>
                    </select></div>
                    <div><input type="radio" disabled class="form-check-input" name="grenzwertconfig" id="alle"
                                value="alle"
                    >
                      <label class="form-check-label mx-2" for="alle">
                        Grenzwerte für alle Preise
                      </label>
                    </div>
                    <div><input type="radio" disabled class="form-check-input" name="grenzwertconfig" id="ab" value="ab"
                    >
                      <label class="form-check-label mx-2 smalltext" for="ab">
                        Grenzwerte für Preise ab <select class="form-select-sm" name="grenzwert" [(ngModel)]="grenzwert"
                                                         (change)="updateGrenzwerte()">
                        <option value="0">0 €</option>
                        <option value="5">5 €</option>
                        <option value="10">10 €</option>
                        <option value="15">15 €</option>
                        <option value="20">20 €</option>
                        <option value="25">25 €</option>
                        <option value="30">30 €</option>
                        <option value="35">35 €</option>
                        <option value="40">40 €</option>
                        <option value="45">45 €</option>
                        <option value="50">50 €</option>
                        <option value="55">55 €</option>
                        <option value="60">60 €</option>
                      </select>
                      </label>
                    </div>
                  </div>
                  <div class="col-6 bg-white smalltext" *ngIf="country === 'AT'">
                    Zusätzliche Grenzwerte
                    <div class="row">
                      <div class="col-2"><select class="form-select-sm">
                        <option value="0">0 €</option>
                        <option value="10">10 €</option>
                        <option value="15">15 €</option>
                        <option value="20">20 €</option>
                        <option value="25">25 €</option>
                        <option value="30">30 €</option>
                      </select></div>
                      <div class="col-1">bis</div>
                      <div class="col-2"><select class="form-select-sm">
                        <option value="0">0 €</option>
                        <option value="10">10 €</option>
                        <option value="15">15 €</option>
                        <option value="20">20 €</option>
                        <option value="25">25 €</option>
                        <option value="30">30 €</option>
                      </select></div>
                      <div class="col-1">=</div>
                      <div class="col-2"><select class="form-select-sm">
                        <option value="0">0 €</option>
                        <option value="10">10 €</option>
                        <option value="15">15 €</option>
                        <option value="20">20 €</option>
                        <option value="25">25 €</option>
                        <option value="30">30 €</option>
                      </select></div>
                      <div class="col-3">
                        <button class="btn-primary btn-sm" disabled>x</button>
                      </div>
                    </div>
                    <div class="col-12 mt-2">
                      <button class="btn-primary btn-sm" disabled>Grenzwerte Hinzufügen</button>
                    </div>
                  </div>
                </div>
                <div *ngIf="country==='DE'">
                  <label class="form-check-label mx-2" for="ab">
                    Grenzwerte für Preise ab <select class="form-select-sm">
                    <option value="10">10 €</option>
                    <option value="15">15 €</option>
                    <option value="20">20 €</option>
                    <option value="25">25 €</option>
                    <option value="30">30 €</option>
                    <option value="30">35 €</option>
                    <option value="30">40 €</option>
                    <option value="30">45 €</option>
                  </select>
                  </label>
                </div>
                <div class="col-12 mt-3" *ngIf="country === 'AT'">
                  <h3>Übernahme Ausschlussartikel</h3>
                  <p>Typische Ausschluss-Artikel können bei der Erstsimulation übernommen werden. Wählen Sie die
                    gwünschte
                    Liste
                  </p>
                  <div><select class="form-select">
                    <option>Bitte Ausschlussliste wählen</option>
                    <option>Variante 1</option>
                    <option>Variante 2</option>
                    <option>Variante 3</option>
                  </select></div>
                </div>
                <br><br><br>
              </div>
            </div>
          </div>
          <div class="col-4"></div>
        </div>
        <!-- Schritt 4 - Bestätigung -->
        <div class="form-floating mb-1" *ngIf="step===5" ngModelGroup="confirmation">
          <div class="mx-3 p-0"><h3>Schritt 5 - Bestätigung</h3></div>
          <p class="mx-3">Alles klar! Die Preise werden nun der verantwortlichen Person zur Freigabe vorgelegt.<br>
            Eine E-Mail wurde versendet und eine Aufgabe im Kundencenter angelegt.</p>
        </div>


      </div>
    </div>
  </div>
  <footer class="fixed-bottom p-2 container-fluid solvena-bottom-toolbar" align="right ">
    <div class="row">
      <div class="col-6 bg-white" align="left">
        <button *ngIf="step < 5" type="button" class="btn-secondary" (click)="backClick()">Zurück</button>
      </div>
      <div class="col-6 bg-white" align="right">
        <button *ngIf="step < 4  " class="btn-primary" type="submit">
          Weiter
        </button>
        <button *ngIf="step === 4" class="btn-primary" [disabled]="!f.valid" (click)="startWorkflow()">Zur
          Freigabe
        </button>
      </div>
    </div>
  </footer>

  <div [ngStyle]="{'display':showModal}" class="modal" role="dialog" data-bs-backdrop="static">
    <div class="modal-dialog" role="document">
      <div class="modal-content w-100">
        <div class="modal-header">
          <h3 class="modal-title">Preisliste Produkte aus Preiselastizitätscluster {{selectedCluster}}</h3>
        </div>
        <div class="modal-body">
          <app-pricetable [showFinalPrice]="true" [productData]="clusterProductData"></app-pricetable>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="closeModal()">Schließen</button>
        </div>
      </div>
    </div>
  </div>
</form>
