export class GlobalVariables {

  public static get countryContext(): string {
    let context = localStorage.getItem('countryContext');
    if (context == null || context === undefined) {
      localStorage.setItem('countryContext', '');
      context = '';
    }
    return context;
  }
  public static set countryContext(context: string) {
    localStorage.setItem('countryContext', context);
  }

  public static userName = '';
  public static userRoles: string[] = [];
  public static kcUser = {};
  public static userid = 0;
  public static userProfile = {} as any;
}
