import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import pharmacydata from '../../../assets/dummydata/pharmacies.json';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-pricelist',
  templateUrl: './pricelist.component.html',
  styleUrls: ['./pricelist.component.css']
})
export class PricelistComponent implements OnInit {

  productData = [] as any;

  pznCount = 0;
  packages = 0;
  turnoverOld = 0;
  turnoverNew = 0;
  showFilter = false;
  editMode = false;
  showModal = 'nono';
  modalTitle = '';
  modalText = '';
  filterCategory = '';
  country = 'DE';

  constructor(protected ar: ActivatedRoute, readonly http:HttpClient) {
    this.pharmacyId = this.ar.snapshot.params.id;
    for (const pharmacy of pharmacydata) {
      console.log(pharmacy.crmId + ' vs ' + this.pharmacyId);
      console.log(pharmacy.crmId == this.pharmacyId);
      if (pharmacy.crmId == this.pharmacyId) {
        console.log('found pharmacy');
        this.pharmacy = pharmacy;
        this.country = this.pharmacy.country;

      }
    }

    if (ar.snapshot.params.category) {
      this.filterCategory = ar.snapshot.params.category;
      console.log('Catagory ' + this.filterCategory);
    }

    this.http.get('/assets/dummydata/' + this.pharmacyId + '.json').subscribe(res => {this.productData = res; console.log('PZNs: ' + this.productData.length)});

//    this.productData = productList;


  }

  pharmacyId = 0;
  pharmacy = <any>{};

  initData(): void {
    this.pznCount = this.productData.length;
    for (const prod of this.productData) {
      this.packages += prod.units;
      this.turnoverOld += prod.pricebefore * prod.units;
      this.turnoverNew += prod.price * prod.units;

      prod.originalprice = prod.price;
      prod.originalownprice = prod.ownprice;
      prod.originalexcluded = prod.excludedprice;

      const rnd = Math.random();
      if (rnd > 0.9 ) {
        prod.excludedprice = true;
      }
      if (rnd < 0.08) {
        prod.permanentprice = true;
      }
      if (rnd > 0.5 && rnd < 0.57) {
        prod.ownprice = true ;
      }
    }
  }

  save(): void {
    this.showModal = 'block';
    this.modalTitle = 'Preisliste geändert';
    this.modalText = 'Die Preise wurden in unserer Datenbank angepasst. Bei der nächsten Ausspielung in Ihr Kassensystem werden sie berücksichtigt';
    this.editMode = false;
  }

  cancel(): void {
    this.editMode = false;
  }

  ngOnInit(): void {
    this.initData();
  }



  backClick(): void {
    window.history.back();
  }

  toggleFilter(): void {
    this.showFilter = !this.showFilter;
  }

  edit(): void {
    this.editMode = true;
  }

  approveClick(): void {
    this.showModal = 'block';
    this.modalTitle = 'Preise freigegeben';
    this.modalText = 'Alles klar. Sie haben die neuen Preise freigegeben. Die Preisliste wird demnächst in ihr Kassensystem eingespielt. Sie erhalten von uns eine E-Mail, sobald die neuen Preise aktiv sind.';
  }
  saveClick(): void {
    this.showModal = 'block';
    this.modalTitle = 'Preise gespeichert';
    this.modalText = 'Die geänderten Preise und die geänderten Berechnungsparameter wurden gespeichert, aber noch nicht freigegeben. Sie können beim nächsten Aufruf der Freigabeoberfläche direkt weiterarbeiten';
  }
  closeModal(): void {
    this.showModal = 'none';
  }

}
