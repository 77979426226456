<app-adminheader active="pharmacies"></app-adminheader>
<div class="container-fluid mx-2 my-3">
  <div class="row">
    <div><h2>True Price Preislisten und Simulationen</h2></div>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="card">
        <div class="card-header">Gültig für Apotheke</div>
        <div class="card-body" style="color: #555">
          {{pharmacy.name}}<br>
          {{pharmacy.street}}<br>
          {{pharmacy.zipcode}} {{pharmacy.city}}<br>
        </div>
      </div>
    </div>
    <div class="col-6">
     <!--<div class="card">
        <div class="card-header">Verknüpfte Apotheken</div>
        <div class="card-body" style="color: #555">
          Musterfiliale Weinheim<br>
          Am Krankenaus 12<br>
          67263 Musterhausen<br>
          <span style="color: darkseagreen">Gemeinsame Berechnungsbasis</span>
        </div>
        <div class="mx-3">
          <button type="button" class="btn-primary btn-sm">Apotheke verknüpfen</button>
        </div>
      </div>-->
    </div>
    <div class="col-6 mt-3">
      <div class="card">
        <div class="card-header">Freiwahl</div>
        <div class="card-body" style="color: #555">
          <div class="bg-light mb-2 solvenabold">Aktuelle Preisliste</div>
          <div class="row mb-4">
            <div class="col-5">Erstellt als</div>
            <div class="col-7">Erstsimulation</div>
            <div class="col-5">Freigegeben am</div>
            <div class="col-7">17.02.2022</div>
            <div class="col-5">Freigegeben durch</div>
            <div class="col-7">{{pharmacy.owner}}</div>
            <div class="col-5">Eingespielt am</div>
            <div class="col-7">18.02.2022</div>
          </div>

          <button type="button" class="btn-primary" (click)="openPricelist('Freiwahl')">Öffnen</button>
          <button type="button" class="btn-primary mx-2" disabled >PDF</button>
          <button type="button" class="btn-primary" disabled>CSV</button>
          <br><br>
          <button type="button" class="btn-primary" (click)="startSimulation(pharmacy.country,'Freiwahl')">Simulation starten</button>
        </div>
      </div>


    </div>
    <div class="col-6 mt-3">
      <div class="card">
        <div *ngIf="country==='DE'" class="card-header">OTC</div>
        <div *ngIf="country==='AT'" class="card-header">Sichtwahl</div>
        <div class="card-body" style="color: #555">

          <div class="bg-light mb-2 solvenabold"><i class="fa fa-exclamation-triangle"></i> Preisliste zur Freigabe</div>
          <div class="row mb-4">
            <div class="col-5">Erstellt als</div>
            <div class="col-7">Folgesimulation</div>
            <div class="col-5">Erstellt am</div>
            <div class="col-7">20.02.2022</div>
            <div class="col-5">Erstellt von</div>
            <div class="col-7">Solvena Vertrieb</div>
            <div class="col-12">
              <button type="button" class="btn-primary btn-sm" (click)="approve()">Prüfen</button>
              <!--<button type="button" class="btn-primary btn-sm mx-3">Löschen</button>-->
            </div>
          </div>
          <div class="bg-light mb-2 solvenabold">Aktuelle Preisliste</div>
          <div class="row mb-4">
            <div class="col-5">Erstellt als</div>
            <div class="col-7">Erstsimulation</div>
            <div class="col-5">Freigegeben am</div>
            <div class="col-7">17.02.2022</div>
            <div class="col-5">Freigegeben durch</div>
            <div class="col-7">{{pharmacy.owner}}</div>
            <div class="col-5">Eingespielt am</div>
            <div class="col-7">18.02.2022</div>
          </div>

          <button type="button" class="btn-primary" (click)="openPricelist('OTC')">Öffnen</button>
          <button type="button" class="btn-primary mx-2" disabled>PDF</button>
          <button type="button" class="btn-primary" disabled>CSV</button>
          <br><br>
          <button type="button" class="btn-primary" disabled>Simulation starten</button>
        </div>
      </div>
    </div>
    <!--<div class="col-4 mt-3">
      <div class="card">
        <div class="card-header">Hilfsmittel</div>
        <div class="card-body" style="color: #555">

           <div class="row mb-4">
            <div class="col-5">Erstellt durch</div>
            <div class="col-7">Update</div>
            <div class="col-5">Freigegeben am</div>
            <div class="col-7">17.02.2022</div>
            <div class="col-5">Freigegeben durch</div>
            <div class="col-7">Herr Max Headroom</div>
            <div class="col-5">Eingespielt am</div>
            <div class="col-7">18.02.2022</div>
          </div>

          <button type="button" class="btn-primary" (click)="openPricelist()">Online öffnen</button>
          <button type="button" class="btn-primary mx-2">PDF laden</button>
          <button type="button" class="btn-primary">CSV laden</button>
          <button type="button" class="btn-primary mx-2" (click)="openHotfix()">Hotfix</button><br><br>
          <button type="button" class="btn-primary" (click)="startSimulation(pharmacy.country, 'Hilfsmittel')">Erstsimulation starten</button>
        </div>
      </div>
    </div>-->

  </div>
</div>
<footer class="fixed-bottom p-2 container-fluid solvena-bottom-toolbar" align="right ">
  <div class="row">

    <div class="col-3 bg-white" align="left">
      <button type="button" class="btn-secondary" (click)="backClick()">Zurück</button>
    </div>
    <div class="col-9  bg-white" align="right">
      <button type="button" class="btn-primary " (click)="upload()" disabled>Preisliste hochladen</button>
    </div>
  </div>
</footer>
