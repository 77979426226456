<app-adminheader active="pharmacies"></app-adminheader>
<div class="container-fluid mx-2 my-3">
  <div class="row">
    <div><h3>Preisliste importieren </h3></div>
    <div class="row kpi-header mb-3">
      <div class="col-6">Musterapotheke Weinheim<br>Karpfenweiher 12<br>67263 Musterhausen</div>
    </div>
  </div>
  <div class="row ">
    <div class="col-8"><input type="file" class="form-control-small"></div>
    <div class="col-4 ">
      <button type="button" class="btn-primary" (click)="dataLoaded=true">Daten laden</button>
    </div>
  </div>
  <table width="100%" *ngIf="dataLoaded">
    <thead>
    <td>Warengruppe</td>
    <td>PZN</td>
    <td>Produkt</td>
    <td>Hersteller</td>
    <td align="right">Preis</td>
    <td align="center">Dauer-<br>preis</td>
    <td align="center">Aus-<br>schluss</td>
    </thead>
    <tbody>
    <tr>
      <td colspan="5"></td>
      <td align="center"><input type="checkbox" class="form-check-input" (click)="inheritPermanentPrice()"
                                [(ngModel)]="checkAllPermanent"></td>
      <td align="center"><input type="checkbox" class="form-check-input" (click)="inheritExcludedPrice()"
                                [(ngModel)]="checkAllExcluded"></td>
    </tr>
    <tr *ngFor="let product of productData">
      <td>{{product.Warengruppe}}</td>
      <td>{{product.PZN}}</td>
      <td>{{product.Produkt}}</td>
      <td>{{product.Hersteller}}</td>
      <td align="right" class="solvenabold"><input *ngIf="!product.excludedprice" [(ngModel)]="product.finalPrice"
                                                   type="text"
                                                   style="text-align:right; width: 70px;"
      ><span *ngIf="product.excludedprice">---</span> EUR
      </td>
      <td align="center"><input type="checkbox" class="form-check-input" [(ngModel)]="product.permanentprice"></td>
      <td align="center"><input type="checkbox" class="form-check-input" [(ngModel)]="product.excludedprice"></td>
    </tr>
    </tbody>
  </table>

</div>

<footer class="fixed-bottom p-2 container-fluid solvena-bottom-toolbar" align="right ">
  <div class="row">

    <div class="col-5 bg-white" align="left">
      <button type="button" class="btn-secondary" (click)="backClick()">Zurück</button>
    </div>
    <div class="col-5  bg-white" align="right">
      <div class="row">
        <div class="col-9"><select *ngIf="dataLoaded" class="form-control-small" [(ngModel)]="selectedImport">
          <option value="">Import-Typ wählen</option>
          <option value="Preise">Preise ohne Neuberechnung einspielen</option>
          <option value="Preise">Preishinterlegung für nächste Simulation</option>
          <option value="Prozent">Preise um x % erhöhen und hinterlegte Rundung</option>
        </select>
        </div>
        <div class="col-2 py-0" *ngIf="dataLoaded && selectedImport=='Prozent'"><input type="text"
                                                                                       class="form-control-small py-0"
                                                                                       [(ngModel)]="percent"></div>
        <div class="col-1 py-0" *ngIf="dataLoaded && selectedImport=='Prozent'">%</div>
      </div>
    </div>
    <div class="col-2  bg-white" align="right">

      <button type="button" class="btn-primary " (click)="saveClick()"
              [disabled]="!dataLoaded || selectedImport === '' || (selectedImport ==='Prozent' && percent ==='')">
        Preisliste
        importieren
      </button>
    </div>
  </div>
</footer>
<div [ngStyle]="{'display':showModal}" class="modal" role="dialog" data-bs-backdrop="static">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">{{modalTitle}}</h3>
      </div>
      <div class="modal-body">
        <p>{{modalText}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="closeModal()">Schließen</button>
      </div>
    </div>
  </div>
</div>
