import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-pharmacypricelist',
  templateUrl: './pharmacypricelist.component.html',
  styleUrls: ['./pharmacypricelist.component.css']
})
export class PharmacypricelistComponent implements OnInit {

  category = 'Kategorie';

  constructor(protected ar: ActivatedRoute) {
    if (ar.snapshot.params.category) {
      this.category = ar.snapshot.params.category;
    }
  }

  ngOnInit(): void {
  }

}
