import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  username = '';
  password = '';
  error = false;

  constructor(protected router: Router) {
  }

  ngOnInit(): void {
  }

  login(): void {
    switch (this.username) {
      case 'admin':
        if (this.password === 'admin') {
          this.router.navigateByUrl('/admin');
        } else {
          this.error = true;
        }
        break;
      case 'apotheke':
        if (this.password === 'apotheke') {

          this.router.navigateByUrl('/pharmacy');
        } else {
          this.error = true;
        }
        break;
      default:
        this.error = true;
        break;
    }
  }

}
