<nav id="mainnav" class="px-4 py-0 sticky-top mainnav navbar navbar-expand-lg container-fluid">
Anmeldung
</nav>
<div class="container justify-content-center d-flex">

<table style="height: calc(100vh - 120px);width: 60%">
  <tr>
    <td align="">
      <div class="card">
        <form>
          <div class="form-group alert-danger p-3 rounded-1" *ngIf="error">
            Hier ist etwas schiefgelaufen. Bitte prüfen Sie Benutzername und Passwort.
          </div>
          <div class="form-group m-4 justify-content-start">
            <label for="email">E-Mail-Adresse</label>
            <input type="text" class="form-control" id="email" name="email" tabindex="1" [(ngModel)]="username">
          </div>
          <div class="form-group m-4 justify-content-start">
            <div class="row">
              <div class="col-6"><label for="email">Passwort</label></div>
              <div class="col-6 justify-content-end d-flex"><a href="#">Passwort vergessen</a></div>
            </div>
            <input type="password" class="form-control" id="password" name="password" tabindex="2" [(ngModel)]="password">
          </div>
          <div class="row m-4 p-0">
            <div class="col-6 justify-content-start m-0 p-0">
              <a href="/register">Registrieren</a>
            </div>
            <div class="col-6 d-flex justify-content-end">
              <button type="submit" class="btn-primary" tabindex="3" (click)="login()">Anmelden</button>
            </div>
          </div>


        </form>
      </div>
    </td>
  </tr>
</table>
</div>
