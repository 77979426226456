<app-adminheader active="pharmacies"></app-adminheader>

<div class="container-fluid bg-white my-2 py-2">
  <form><div class="row">
    <div class="col-6 col-md-6 d-none d-md-flex"><h3>True Price Kunden</h3></div>
    <div class="col-12 col-md-6 d-flex input-group-sm">
      <input class="form-control" name="query" type="search"><button type="button" class="btn-primary" >Suchen</button>
    </div>
  </div>
  </form>
</div>

<div class="container-fluid solvenatable">
  <div class="row white d-none d-md-flex bg-white my-2 p-1 header">
    <div class="col-md-1 col-6 th" >KdNr. </div>
    <div class="col-md-1 col-6 th" >Status </div>
    <div class="col-md-3 col-12 th" >Apotheke </div>
    <div class="col-md-2 col-12 th" >Strasse </div>
    <div class="col-md-2 col-3 th" >PLZ Ort </div>
    <div class="col-md-1 col-3 th" >Land</div>
    <div class="col-md-2"></div>
  </div>
  <div class="row my-2 bg-white p-1" *ngFor="let pharmacy of pharmacies ">
    <div class="col-md-1  align-middle d-flex align-items-center"><span *ngIf="pharmacy.token==''">{{pharmacy.crmId}}</span></div>
    <div class="col-md-1 col-6 d-flex align-items-center">{{pharmacy.status}}</div>
    <div class="col-md-3 col-12  align-middle d-flex align-items-center">{{pharmacy.name}}</div>
    <div class="col-md-2 col-12 align-middle d-flex align-items-center">{{pharmacy.street}}</div>
    <div class="col-md-2 col-12 align-middle d-flex align-items-center">{{pharmacy.zipcode}} {{pharmacy.city}}</div>
    <div class="col-md-1 col-12 align-middle d-flex align-items-center">{{pharmacy.country}}</div>
    <div class="col-md-2 justify-content-end d-flex"  >
      <button type="button" (click)="openPharmacy(pharmacy.crmId)" class="btn-primary my-auto btn-sm">Öffnen</button>
    </div>
  </div>
  <div *ngIf="pharmacies.length==0">Es wurden keine Apotheken gefunden.</div>
</div>
