import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import pharmacydata from '../../../assets/dummydata/pharmacies.json';

@Component({
  selector: 'app-adminhomepage',
  templateUrl: './adminhomepage.component.html',
  styleUrls: ['./adminhomepage.component.css']
})
export class AdminhomepageComponent implements OnInit {

  pharmacies = <any>[];

  constructor(protected router: Router) {
    this.pharmacies = pharmacydata;
  }

  ngOnInit(): void {
  }

  openPharmacy(crmId: number): void {
    this.router.navigateByUrl('/admin/pharmacies/' + crmId);
  }
}
